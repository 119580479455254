.header {
  width: 100%;
  display: block;
  padding-top: 0px;
  z-index: 999;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

.headerwp {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0;
}

.posrlt {
  position: relative;
}

#header-2 .wsmainfull.cloned .wsmenu {
  float: right;
}

#header-2 .wsmainfull.cloned .desktoplogo {
  padding: 16px 0px 0px 0px;
  display: block;
}

#header-2 .wsmainfull.cloned .wsmenu > .wsmenu-list > li > a {
  padding: 10px 32px 10px 18px;
}

#header-2 .wsmainfull.cloned .wsmenu > .wsmenu-list > li.header-btn a {
  display: block;
  padding: 5px 15px 5px 15px;
  margin-left: 0;
}

#header-2 .wsmainfull.cloned  .header-button {
  display: none;
}

/*------------------------------------------*/
/*   HEADER STRIP
/*------------------------------------------*/

.headtoppart {
  display: block;
  width: 100%;
  height: 40px;
  margin: 0px auto;
  padding: 0px 0px;
  min-width: 320px;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

.headtoppart.bg-lightgrey {
  background-color: #f2f2f2;
}

.headertopleft {
  width: 50%;
  float: left;
  padding-left: 15px;
}

.headertopright {
  width: 50%;
  float: right;
  text-align: right;
  padding-right: 15px;
}

.headertopright a {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  color: #fff;
  font-size: 13px;
  float: right;
}

.headertopright a:hover {
  background-color: #272B37;
}

.headertopright a.facebookicon:hover {
  background-color: #3b5998;
  color: #fff;
}

.headertopright a.twittericon:hover {
  background-color: #55acee;
  color: #fff;
}

.headertopright a.linkedinicon:hover {
  background-color: #0077b5;
  color: #fff;
}

.headertopright a.googleicon:hover {
  background-color: #dd4b39;
  color: #fff;
}

.address a {
  color: #fff;
  font-size: 13px;
  line-height: 40px;
}

.address span {
  color: #fff;
  font-size: 13px;
  line-height: 40px;
  margin-right: 9px;
}

.address i {
  position: relative;
  color: #fff;
  font-size: 11px;
  top: -1px;
  margin-right: 7px;
  margin-left: 5px;
}

.norlt {
  position: static;
}

.menuwrapper {
  position: relative;
}

.show-grid {
  background-color: #fff;
}

.btn-border {
  border: 2px solid;
  border-radius: 40px;
  display: inline-block;
  font-size: 13px;
  text-transform: uppercase;
  -moz-user-select: none;
  background-image: none;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  color: #fff;
  padding: 15px 40px;
}

.btn-border:hover {
  color: #fff;
  text-decoration: none;
  opacity: 0.9;
}

.btn-border:focus {
  color: #fff;
  text-decoration: none;
  opacity: 0.9;
}

.mobiletext02 {
  display: none;
}


.bg-lightgrey .address a,
.bg-lightgrey .address span {
  color: #747373;
}

.bg-lightgrey .address i,
.bg-lightgrey .headertopright a {
  color: #5f5f5f;
}

@media only screen and (min-width: 230px) and (max-width:991px) {

  .headtoppart {
    display: none;
  }

  .swichermainbx {
    width: 96%;
    margin: 25% 2%;
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 15px 25px;
    border-radius: 14px;
  }

  .swichermainleft, .swichermainright {
    width: 98%;
    margin: 1% 1%;
  }

  .wscolorcode {
    width: 12.5%;
  }

  .swichertxt {
    display: none;
  }

  .swichertxt02 span {
    display: none;
  }
}

/*------------------------------------------*/
/*   HEADER WIDGET
/*------------------------------------------*/

.hero-widget {
  padding: 15px 0;
  border-bottom: 1px solid #eee;
}

.header-widget {
  margin-top: 5px;
}

.header-widget span {
  float: left;
  margin-right: 8px;
}

.header-widget.icon-xs [class*="flaticon-"]:before,
.header-widget.icon-xs [class*="flaticon-"]:after {
  font-size: 2rem;
}

.header-widget p {
  font-size: 14px;
  line-height: 1;
  margin-bottom: 5px;
}

p.header-widget-phone {
  font-size: 17px;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  margin-top: 3px;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*   MEGAMENU LATEST NEWS
/*------------------------------------------*/

.wsmegamenu {
  display: none;
}

.wsmegamenu .latest-news li {
  padding: 15px 0;
  border-bottom: 1px dashed #c0c0c0;
}

.wsmegamenu .latest-news li:first-child {
  padding: 0 0 15px;
}

.wsmegamenu .latest-news li:last-child {
  padding: 15px 0 0;
  border-bottom: 0;
}

.wsmegamenu .latest-news img {
  text-align: center;
  float: left;
  width: 85px;
  height: 85px;
}

.wsmegamenu .post-summary {
  overflow: hidden;
  padding-left: 20px;
}

/*------------------------------------------*/
/*  MEGAMENU LATEST NEWS TYPOGRAPHY
--------------------------------------------*/

.wsmegamenu .post-summary a {
  color: #888;
  font-size: 15px;
}

.wsmegamenu .latest-news .post-summary a:hover {
  color: #333;
  text-decoration: underline;
}

.wsmegamenu .latest-news p {
  color: #999;
  font-size: 14px;
  margin-top: 6px;
  margin-bottom: 0;
}