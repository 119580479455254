#appointment-form-holder {
  margin: 40px 0 25px;
}

/*------------------------------------------*/
/*  Appointment Form Input
/*------------------------------------------*/

.appointment-form .form-control,
.appointment-form .custom-select {
  background-color: #fff;
  border: 2px solid #ddd;
  color: #333;
  height: 54px;
  font-size: 16px;
  line-height: 1;
  font-weight: 400;
  padding: 4px 15px;
  margin-bottom: 20px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  box-shadow: none;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.appointment-form .custom-select {
  display: inline-block;
  width: 100%;
  color: #777;
  margin-bottom: 20px;
}

/*------------------------------------------*/
/*  Appointment Form Textarea
/*------------------------------------------*/

.appointment-form textarea { min-height: 160px; }
.appointment-form textarea.form-control { padding: 20px 15px; }

/*------------------------------------------*/
/*  Appointment Form Input Focus
/*------------------------------------------*/

.appointment-form .form-control:focus {
  background-color: #fff;
  border-color: #00a3c8;
  outline: 0px none;
  box-shadow: none;
}

/*------------------------------------------*/
/*  Appointment Form Button
/*------------------------------------------*/

.appointment-form .btn {
  display: block;
  width: 100%;
  height: 52px;
  font-size: 16px;
  margin-top: 15px;
}

.appointment-form .btn:focus {
  outline: 0px none;
  box-shadow: none;
}

/*------------------------------------------*/
/*  Appointment Form Message
/*------------------------------------------*/

.appointment-form label.error {
  color: #e74c3c;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 20px;
}

.appointment-form-msg {
  width: 100%!important;
  display: block;
  text-align: center;
}

.appointment-form .sending-msg {
  margin-top: 20px;
}

.appointment-form .loading {
  color: #666;
  font-size: 17px;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
}