#pricing-1 .pricing-table {
  text-align: center;
  background-color: #fff;
  border: 1px solid #d0d0d0;
  padding: 60px 50px;
  margin-top: 0;
  margin-bottom: 40px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.pricing-table {
  padding: 20px 25px 5px;
  border: 1px solid #d0d0d0;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  margin-top: 25px;
}

#pricing-1.bg-lightgrey .pricing-table {
  background-color: #fff;
  border: 1px solid #f5f5f5;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.pricing-table .table td, .pricing-table  .table th {
  padding: 12px 10px;
  border-top: none;
  border-bottom: 1px solid #ddd;
}

.pricing-table .table .last-tr td,
.pricing-table .table .last-tr th {
  border-bottom: none;
}

.pricing-table td span {
  font-weight: 700;
}

/*------------------------------------------*/
/*    Pricing Table Price Plan
/*------------------------------------------*/

#pricing-1 .pricing-table h5 {
  margin-top: 30px;
  margin-bottom: 20px;
}

.pricing-table span.price {
  font-size: 70px;
  font-family: 'Lato', sans-serif;
  line-height: 1;
  font-weight: 700;
  letter-spacing: -0.5px;
}

.pricing-table sup {
  font-size: 45px;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  top: -7px;
  right: 2px;
}

.pricing-plan p {
  color: #888;
  margin-top: 10px;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*    Pricing Table Body
/*------------------------------------------*/

#pricing-1 .pricing-table ul.features {
  padding: 25px 0 35px;
}

#pricing-1 .pricing-table ul.features li {
  color: #777;
  font-size: 1rem;
  line-height: 1;
  font-weight: 300;
  padding: 9px 0;
}

#pricing-1 .pricing-table ul.features li:last-child {
  border-bottom: none;
}

/*------------------------------------------*/
/*   More Pricing Button
/*------------------------------------------*/

.all-pricing-btn {
  margin-top: 10px;
}

#pricing-1 .all-pricing-btn {
  margin-top: 30px;
}

/*------------------------------------------*/
/*  Pricing Notice Text
/*------------------------------------------*/

.all-pricing-btn p {
  padding: 0 5%;
  margin-bottom: 30px;
}

.all-pricing-btn p span {
  font-weight: 700;
  margin-right: 5px;
}

/*------------------------------------------*/
/*   PRICING IMAGE
/*------------------------------------------*/

.pricing-img {
  text-align: center;
  position: relative;
  margin-bottom: 40px;
}

.pricing-img img {
  max-width: none;
  display: inline-block;
}