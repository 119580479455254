/*------------------------------------------*/
/*  HEADERS
/*------------------------------------------*/

h1, h2, h3, h4, h5, h6 {
  color: #222;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  letter-spacing: -0.25px;
}

/* Header H5 */
h5.h5-xs { font-size: 1.125rem; }  /* 18px */
h5.h5-sm { font-size: 1.1875rem; } /* 19px */
h5.h5-md { font-size: 1.25rem;  }  /* 20px */
h5.h5-lg { font-size: 1.375rem;  } /* 22px */
h5.h5-xl { font-size: 1.5rem; }    /* 24px */

/* Header H4 */
h4.h4-xs { font-size: 1.625rem; }  /* 26px */
h4.h4-sm { font-size: 1.75rem; }   /* 28px */
h4.h4-md { font-size: 1.875rem; }  /* 30px */
h4.h4-lg { font-size: 2rem; }      /* 32px */
h4.h4-xl { font-size: 2.125rem; }  /* 34px */

/* Header H3 */
h3.h3-xs { font-size: 2.375rem; }  /* 38px */
h3.h3-sm { font-size: 2.5rem; }    /* 40px */
h3.h3-md { font-size: 2.625rem; }  /* 42px */
h3.h3-lg { font-size: 2.75rem; }   /* 44px */
h3.h3-xl { font-size: 3rem; }      /* 48px */

/* Header H2 */
h2.h2-xs { font-size: 3.25rem; }   /* 52px */
h2.h2-sm { font-size: 3.375rem; }  /* 54px */
h2.h2-md { font-size: 3.5rem; }    /* 56px */
h2.h2-lg { font-size: 3.75rem; }   /* 60px */
h2.h2-xl { font-size: 4rem; }      /* 64px */
h2.h2-huge { font-size: 5rem; }    /* 80px */

/*------------------------------------------*/
/*  PARAGRAPHS
/*------------------------------------------*/

p.p-sm { font-size: 0.95rem; }     /* 15.2px */
p { font-size: 1rem; }             /* 16px */
p.p-md { font-size: 1.125rem; }    /* 18px */
p.p-lg { font-size: 1.25rem; }     /* 20px */
p.p-xl { font-size: 1.3175rem; }   /* 22px */

/*------------------------------------------*/
/*  LINK SETTINGS
/*------------------------------------------*/

a {
  color: #333;
  text-decoration: none;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

a:hover {
  color: #666;
  text-decoration: none;
}

a:focus {
  outline: none;
  text-decoration: none;
}

/*------------------------------------------*/
/*  LISTS
/*------------------------------------------*/

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

/*------------------------------------------*/
/*   CONTENT LIST
/*------------------------------------------*/

ul.content-list {
  list-style: disc;
  margin-left: 15px;
}

ul.content-list li {
  font-size: 1rem;
  margin-bottom: 7px;
}

ul.content-list li p,
ul.content-list li:last-child {
  margin-bottom: 0;
}

/*------------------------------------------*/
/*   BOX LIST
/*------------------------------------------*/

.box-list-icon {
  font-size: 0.75rem;
  line-height: 1.5rem;
  float: left;
  margin-right: 7px;
}

.box-list p {
  overflow: hidden;
  margin-bottom: 8px;
}

/*------------------------------------------*/
/*  BUTTON SETTINGS
/*------------------------------------------*/

.btn {
  background-color: transparent;
  color: #fff;
  font-size: 0.915rem;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 0.25px;
  padding: 12px 24px;
  border: 2px solid transparent;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

/*------------------------------------------*/
/*   Button Size
/*------------------------------------------*/

.btn.btn-sm {
  font-size: 0.78125rem;
  line-height: 1;
  padding: 10px 20px;
}

.btn.btn-md {
  font-size: 1rem;
  line-height: 1;
  padding: 14px 42px;
}

/*------------------------------------------*/
/*  Button Color
/*------------------------------------------*/

.btn-white,
.white-color .btn-white {
  color: #333!important;
  background-color: #fff;
  border-color: #fff;
}

.btn-black,
.white-color .btn-black {
  color: #fff!important;
  background-color: #333;
  border-color: #333;
}

.btn-tra-white,
.white-color .btn-tra-white {
  color: #fff;
  background-color: transparent;
  border-color: #fff;
}

.btn-tra-black,
.white-color .btn-tra-black {
  color: #333;
  background-color: transparent;
  border-color: #333;
}

.btn-tra-grey,
.white-color .btn-tra-grey {
  color: #555;
  background-color: transparent;
  border-color: #ccc;
}

.btn-blue,
.white-color .btn-blue {
  color: #fff;
  background-color: #00a3c8;
  border-color: #00a3c8;
}

.btn-darkgreen,
.white-color .btn-darkgreen {
  color: #fff;
  background-color: #0dc176;
  border-color: #0dc176;
}

.btn-orange,
.white-color .btn-orange {
  color: #fff;
  background-color: #ffa12f;
  border-color: #ffa12f;
}

.btn-lime,
.white-color .btn-lime {
  color: #fff;
  background-color: #89be19;
  border-color: #89be19;
}

/*------------------------------------------*/
/*  Button Hover
/*------------------------------------------*/

.white-hover:hover {
  color: #333;
  background-color: #fff;
  border-color: #fff;
}

.black-hover:hover {
  color: #fff!important;
  background-color: #333;
  border-color: #333;
}

.tra-white-hover:hover {
  color: #fff!important;
  background-color: transparent;
  border-color: #fff;
}

.grey-hover:hover,
.white-color .grey-hover:hover {
  color: #555;
  background-color: #ddd;
  border-color: #ddd;
}

.tra-black-hover:hover,
.white-color .tra-black-hover:hover {
  color: #333!important;
  background-color: transparent;
  border-color: #333;
}

.blue-hover:hover,
.white-color .blue-hover:hover {
  color: #fff!important;
  background-color: #0e8eab;
  border-color: #0e8eab;
}

/*------------------------------------------*/
/*  VIDEO POPUP ICON
/*------------------------------------------*/

.video-preview {
  position: relative;
  text-align: center;
}

.video-block {
  width: 100px;
  height: 100px;
  position: relative;
  display: inline-block;
}

/*------------------------------------------*/
/*  Video Button Icon
/*------------------------------------------*/

.video-btn {
  width: 100px;
  height: 100px;
  position: absolute!important;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  display: inline-block;
  text-align: center;
  background-color: transparent;
  color: #fff;
  line-height: 90px!important;
  border: 4px solid transparent;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

.video-btn.video-btn-sm {
  width: 80px;
  height: 80px;
  margin-top: -40px;
  margin-left: -40px;
  line-height: 70px!important;
}

.video-btn i {
  font-size: 3rem;
  line-height: 92px!important;
  margin-left: 8px;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.video-btn.video-btn-sm i {
  font-size: 38px;
  line-height: 73px!important;
  margin-left: 6px;
}

.video-btn:before {
  content: '';
  position: absolute;
  left: -5px;
  right: -5px;
  top: -5px;
  bottom: -5px;
  background: rgba(255, 255, 255, 0.2);
  opacity: 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.video-btn:hover i {
  -moz-transform: scale(0.9);
  -ms-transform: scale(0.9);
  -webkit-transform: scale(0.9);
  -o-transform: scale(0.9);
  transform: scale(0.9);
}

.video-btn:hover:before {
  opacity: .75;
  left: -25px;
  right: -25px;
  top: -25px;
  bottom: -25px;
}

/*------------------------------------------*/
/*  Video Icon Color
/*------------------------------------------*/

.video-btn.play-icon-white { background-color: #fff; color: #333; border-color: #fff; }
.video-btn.play-icon-tra { background-color: transparent; color: #fff; border-color: #fff; }
.video-btn.play-icon-blue { background-color: #00a3c8; border-color: #00a3c8; }
.video-btn.play-icon-lime { background-color: #89be19; border-color: #89be19; }

/*------------------------------------------*/
/*  BOX ICONS
/*------------------------------------------*/

.icon-xs [class*="flaticon-"]:before,
.icon-xs [class*="flaticon-"]:after {
  font-family: Flaticon;
  font-size: 3.25rem;
  line-height: 1!important;
}

.icon-sm [class*="flaticon-"]:before,
.icon-sm [class*="flaticon-"]:after {
  font-family: Flaticon;
  font-size: 3.75rem;
  line-height: 1!important;
}

.icon-md [class*="flaticon-"]:before,
.icon-md [class*="flaticon-"]:after {
  font-family: Flaticon;
  font-size: 4.25rem;
  line-height: 1!important;
}

.icon-lg [class*="flaticon-"]:before,
.icon-lg [class*="flaticon-"]:after {
  font-family: Flaticon;
  font-size: 4.5rem;
  line-height: 1!important;
}

.icon-xl [class*="flaticon-"]:before,
.icon-xl [class*="flaticon-"]:after {
  font-family: Flaticon;
  font-size: 5rem;
  line-height: 1!important;
}

/*------------------------------------------*/
/*  PNG ICONS SIZE
/*------------------------------------------*/

.img-110 { width: 110px; height: 110px; }
.img-100 { width: 100px; height: 100px; }
.img-95 { width: 95px; height: 95px; }
.img-90 { width: 90px; height: 90px; }
.img-85 { width: 85px; height: 85px; }
.img-80 { width: 80px; height: 80px; }
.img-75 { width: 75px; height: 75px; }
.img-70 { width: 70px; height: 70px; }
.img-65 { width: 65px; height: 65px; }
.img-60 { width: 60px; height: 60px; }
.img-50 { width: 50px; height: 50px; }
.img-40 { width: 40px; height: 40px; }
.img-30 { width: 30px; height: 30px; }
.img-25 { width: 25px; height: 25px; }

/*------------------------------------------*/
/*   SECTION ID
/*------------------------------------------*/

span.section-id {
  font-size: 0.825rem;
  font-family: 'Lato', sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  display: block;
  letter-spacing: 0.5px;
  margin-bottom: 28px;
}

span.id-color {
  color: #ccc;
}

.bg-lightgrey span.id-color {
  color: #aaa;
}

/*------------------------------------------*/
/*  SECTION TITLE
/*------------------------------------------*/

.section-title {
  text-align: center;
  margin-bottom: 60px;
}

.section-title h3.h3-md {
  line-height: 1;
  margin-bottom: 20px;
}

.section-title p {
  color: #888;
  padding: 0 20%;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  TEXT BLOCK HEADERS
/*------------------------------------------*/

.txt-block h4.h4-xl,
.txt-block h3.h3-md {
  margin-bottom: 30px;
}

#pricing-3 .txt-block h3.h3-md {
  margin-bottom: 15px;
}

.txt-block h5.h5-sm {
  margin-top: 15px;
  margin-bottom: 15px;
}

/*------------------------------------------*/
/*  CONTENT BLOCK
/*------------------------------------------*/

.content-block-img {
  margin-top: 35px;
  margin-bottom: 50px;
}

.content-block h5.h5-md {
  margin-bottom: 20px;
}

.content-block p.p-md {
  font-size: 1.05rem;
  text-transform: uppercase;
  margin-bottom: 20px;
}

/*------------------------------------------*/
/*   TEXT WEIGHT
/*------------------------------------------*/

.txt-300 { font-weight: 300; }
.txt-400 { font-weight: 400; }
.txt-500 { font-weight: 500; }
.txt-600 { font-weight: 600; }
.txt-700 { font-weight: 700; }
.txt-800 { font-weight: 800; }
.txt-900 { font-weight: 900; }

.txt-upper { text-transform: uppercase; }

/*------------------------------------------*/
/*   TEXT COLOR
/*------------------------------------------*/

.white-color,
.white-color h2,
.white-color h3,
.white-color h4,
.white-color h5,
.white-color h6,
.white-color p,
.white-color a,
.white-color li,
.white-color i,
.white-color span { color: #fff; }

.grey-color,
.grey-color h2,
.grey-color h3,
.grey-color h4,
.grey-color h5,
.grey-color h6,
.grey-color p,
.grey-color a,
.grey-color li,
.grey-color i,
.grey-color span { color: #666; }

.lightgrey-color,
.lightgrey-color h2,
.lightgrey-color h3,
.lightgrey-color h4,
.lightgrey-color h5,
.lightgrey-color h6,
.lightgrey-color p,
.lightgrey-color a,
.lightgrey-color li,
.lightgrey-color i,
.white-color .lightgrey-color,
.lightgrey-color span { color: #ccc; }

.blue-color,
.blue-color h2,
.blue-color h3,
.blue-color h4,
.blue-color h5,
.blue-color h6,
.blue-color p,
.blue-color a,
.blue-color li,
.blue-color i,
.white-color .blue-color,
.steelblue-color .blue-color,
.blue-color span { color: #00a3c8; }

.darkblue-color,
.darkblue-color h2,
.darkblue-color h3,
.darkblue-color h4,
.darkblue-color h5,
.darkblue-color h6,
.darkblue-color p,
.darkblue-color a,
.darkblue-color li,
.darkblue-color i,
.white-color .darkblue-color,
.darkblue-color span { color: #1d437b; }

.steelblue-color,
.steelblue-color h2,
.steelblue-color h3,
.steelblue-color h4,
.steelblue-color h5,
.steelblue-color h6,
.steelblue-color p,
.steelblue-color a,
.steelblue-color li,
.steelblue-color i,
.white-color .steelblue-color,
.steelblue-color span { color: #004861; }

.lime-color,
.lime-color h2,
.lime-color h3,
.lime-color h4,
.lime-color h5,
.lime-color h6,
.lime-color p,
.lime-color a,
.lime-color li,
.lime-color i,
.white-color .lime-color,
.lime-color span { color: #89be19; }

.pink-color,
.pink-color h2,
.pink-color h3,
.pink-color h4,
.pink-color h5,
.pink-color h6,
.pink-color p,
.pink-color a,
.pink-color li,
.pink-color i,
.white-color .pink-color,
.pink-color span { color: #ff005a; }