#timetable-page {
  padding-top: 100px;
  padding-bottom: 175px;
}

.single-event {
  padding: 20px 0 0;
}

.cd-schedule .timeline {
  padding-top: 65px;
}

.cd-schedule .timeline span {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
}