#terms-page p.p-notice {
  font-size: 0.915rem;
  font-family: 'Lato', sans-serif;
  font-weight: 900;
  letter-spacing: 0.5px;
}

.terms-box {
  margin-bottom: 30px;
}

.terms-box h5 {
  margin-bottom: 25px;
}

.terms-box p span {
  font-size: 1.1rem;
  line-height: 1;
  font-weight: 700;
  margin-right: 5px;
}

.terms-box p a {
  color: #5496ff;
  font-weight: 700;
  text-decoration: underline;
}

.terms-box p a:hover {
  color: #444;
}

/*------------------------------------------*/
/*    Terms / Privacy Title
/*------------------------------------------*/

ul.terms-list {
  list-style: disc;
  margin: 20px 0 20px 25px;
}

ul.terms-list li {
  margin-bottom: 10px;
}

ul.terms-list li:last-child {
  margin-bottom: 0;
}