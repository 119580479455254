.doctor-1 {
  position: relative;
  z-index: 1;
  margin-bottom: 60px;
  overflow: hidden;
}

.doctor-2 {
  text-align: center;
  background-color: #fff;
  border: 1px solid #eee;
  padding: 40px;
  margin-bottom: 40px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

/*------------------------------------------*/
/*    DOCTOR META
/*------------------------------------------*/

.doctor-meta {
  padding-top: 18px;
  padding-bottom: 0;
}

.doctor-meta h5.h5-sm,
.doctor-meta h5.h5-md {
  margin-bottom: 5px;
}

.doctor-meta span {
  display: block;
  color: #888;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
}

.bg-blue .doctor-meta span {
  color: #f5f5f5;
}

.doctor-meta p {
  margin-top: 10px;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*   Doctor Profile Link
/*------------------------------------------*/

.profile-link {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.profile-link a {
  display: inline-block;
  -webkit-transform: translate3d(0, 20px, 0);
  transform: translate3d(0, 20px, 0);
  opacity: 0;
}

/*------------------------------------------*/
/*   Overlay Background
/*------------------------------------------*/

.doctor-1 .item-overlay {
  opacity: 0;
  -moz-opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 163, 200, 0.85);
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.bg-blue .doctor-1 .item-overlay {
  background: rgba(10, 10, 10, 0.55);
}

/*------------------------------------------*/
/*   Doctor Photo Hover Overlay
/*------------------------------------------*/

.doctor-1 .hover-overlay,
.doctor-2 .hover-overlay {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.doctor-1 .hover-overlay > img,
.doctor-2 .hover-overlay > img {
  transform: scale(1.1);
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
  -moz-transform: scale(1.1);
  overflow: hidden;
  -webkit-transition: transform 500ms;
  -moz-transition: transform 500ms;
  -o-transition: transform 500ms;
  transition: transform 500ms;
}

/*------------------------------------------*/
/*   Image Hover Effect
/*------------------------------------------*/

.doctor-1:hover .hover-overlay > img,
.doctor-2:hover .hover-overlay > img {
  transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  -o-transform: scale(1);
  -moz-transform: scale(1);
}

.doctor-1:hover .item-overlay {
  opacity: 1;
  -moz-opacity: 1;
}

.doctor-1:hover a {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/*------------------------------------------*/
/*  All Doctors Button
/*------------------------------------------*/

.all-doctors {
  margin-bottom: 60px;
}

/*------------------------------------------*/
/*  DOCTOR PROFILE
/*------------------------------------------*/

#doctor-breadcrumbs {
  background-image: url(../../../../../frontend/src/assets/images/doctor-profile.jpg);
  padding-top: 130px;
  padding-bottom: 60px;
}

#doctor-breadcrumbs .doctor-data {
  padding-left: 45px;
}

#doctor-1-details {
  padding-bottom: 70px;
}

#doctor-1-details .doctor-bio {
  padding-top: 60px;
  padding-left: 45px;
}

/*------------------------------------------*/
/*  DOCTOR PROFILE TYPOGRAPHY
/*------------------------------------------*/

.doctor-bio h2 {
  letter-spacing: -0.5px;
  margin-bottom: 5px;
}

.doctor-bio h5.h5-lg {
  font-weight: 400;
  margin-bottom: 30px;
}

.doctor-bio h5.h5-md {
  margin-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
}

/*------------------------------------------*/
/*  DOCTOR PHOTO
/*------------------------------------------*/

#doctor-1-details .doctor-photo {
  margin-top: -220px;
  position: relative;
  z-index: 3;
}

/*------------------------------------------*/
/*  Doctor Contacts
/*------------------------------------------*/

.doctor-contacts {
  margin-top: 30px;
  margin-bottom: 35px;
}

.doctor-contacts h4 {
  font-weight: 400;
  margin-bottom: 10px;
}

.doctor-contacts h4 i {
  margin-right: 10px;
}

.doctor-photo .btn {
  margin: 0 10px 15px;
}

/*------------------------------------------*/
/*  CERTIFICATES
/*------------------------------------------*/

.certificates {
  margin-top: 30px;
}

.certificate-image {
  margin-bottom: 20px;
}

/*------------------------------------------*/
/*   Doctor Data
/*------------------------------------------*/

.doctor-info {
  background-color: #f9f9f9;
  border: 1px solid #f5f5f5;
  margin-top: 30px;
  margin-bottom: 40px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

.doctor-info table {
  margin-top: 0;
  margin-bottom: 0;
}

.doctor-info .table td, .doctor-info .table th {
  font-size: 15px;
  padding: 15px 25px;
  border-top: none;
}

.doctor-info .table .last-tr td,
.doctor-info .table .last-tr th {
  border-bottom: none;
}

.doctor-info .table span {
  display: block;
  margin-bottom: 3px;
}

.doctor-info .table span i {
  color: #00a3c8;
  font-size: 12px;
  margin-right: 3px;
}