
.hero-section .container {
  position: relative;
  z-index: 3;
}

#hero-1 {
  background-image: url(../../../../../frontend/src/assets/images/hero-1.jpg);
  padding-top: 30px;
}

#hero-4 {
  background-image: url(../../../../../frontend/src/assets/images/hero-4.jpg);
  padding-top: 80px;
  padding-bottom: 40px;
}

#hero-5 {
  background-image: url(../../../../../frontend/src/assets/images/hero-5.jpg);
  padding-top: 90px;
  padding-bottom: 210px;
}

#hero-12 {
  position: relative;
  padding-top: 100px;
  padding-bottom: 200px;
}

/*------------------------------------------*/
/*  HERO SLIDER
/*------------------------------------------*/

.slider {
  position: relative;
  max-width: 100%;
  height: 600px;
}

.slider .slides {
  margin: 0;
  height: 600px;
}

#hero-3 .slider,
#hero-10 .slider,
#hero-3 .slider .slides,
#hero-10 .slider .slides {
  height: 650px;
}

.slider .slides li {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: inherit;
  overflow: hidden;
}

.slider .slides li img {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.slider .slides li .caption {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.slider .slides li.active {
  z-index: 2;
}

/*------------------------------------------*/
/*  Slider Animation
/*------------------------------------------*/

.center-align {
  text-align: center;
}

/*------------------------------------------*/
/*  Slider Indicators
/*------------------------------------------*/

.slider .indicators {
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  bottom: 10px;
  margin: 0;
  z-index: 98;
}

.slider .indicators .indicator-item {
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  height: 10px;
  width: 10px;
  border: 2px solid #fff;
  margin: 0 7px;
  opacity: .5;
  -webkit-transition: background-color .3s;
  transition: background-color .3s;
  border-radius: 50%;
}

.slider.blue-nav .indicators .indicator-item {
  border: 2px solid #004861;
  opacity: .5;
}

.slider.green-nav .indicators .indicator-item {
  border: 2px solid #0dc176;
  opacity: .5;
}

.slider .indicators .indicator-item.active {
  background-color: #fff;
  opacity: 1;
}

.slider.blue-nav .indicators .indicator-item.active {
  background-color: #004861;
  opacity: 1;
}

.slider.green-nav .indicators .indicator-item.active {
  background-color: #0dc176;
  opacity: 1;
}

/*------------------------------------------*/
/*  HERO IMAGES CAROUSEL
/*------------------------------------------*/

#heroCarousel {
  position: relative;
  z-index: 1;
}

#heroCarousel .carousel-item {
  height: 100%;
  min-height: 620px;
}

#carousel-slide-1 {
  background-image: url(../../../../../frontend/src/assets/images/carousel/slide-1.jpg);
  background-position: top center;
}

#carousel-slide-2 {
  background-image: url(../../../../../frontend/src/assets/images/carousel/slide-2.jpg);
}

#carousel-slide-3 {
  background-image: url(../../../../../frontend/src/assets/images/carousel/slide-3.jpg);
}

#heroCarousel .mask {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.carousel-control-next,
.carousel-control-prev {
  height: 45px;
  width: 45px;
  padding: 5px;
  top: 50%;
  bottom: auto;
  transform: translateY(-50%);
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  width: 35px;
  height: 35px;
}

.carousel-control-next-icon {
  background-image: url(../../../../../frontend/src/assets/images/next.svg);
}

.carousel-control-prev-icon {
  background-image: url(../../../../../frontend/src/assets/images/prev.svg);
}

.carousel-control-next {
  right: 25px;
}

.carousel-control-prev {
  left: 25px;
}

/*------------------------------------------*/
/*  HERO TEXT
/*------------------------------------------*/

#hero-1 .hero-txt {
  margin-top: -60px;
}

#hero-3 .caption-txt {
  margin-top: -100px;
  padding-left: 30px;
}

.hero-5-strip {
  padding: 20px 40px;
  display: inline-block;
  -webkit-border-radius: 6px 6px 0 0;
  -moz-border-radius: 6px 6px 0 0;
  border-radius: 6px 6px 0 0;
}

#hero-5 .hero-txt {
  background-color: #fff;
  padding: 50px 40px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px 6px;
  border-radius: 0 6px 6px 6px;
}

#hero-6 .caption-txt {
  margin-top: -80px;
}

#hero-10 .caption-txt {
  margin-top: -90px;
}

#hero-11 .caption-txt {
  margin-top: -100px;
}

#hero-12 .hero-txt {
  padding-right: 40px;
  padding-left: 20px;
}

/*------------------------------------------*/
/*  Hero Headers
/*------------------------------------------*/

#hero-1 h2 {
  font-size: 4.5rem;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 0;
  margin-bottom: 25px;
}

#hero-1 h5,
#hero-4 h5,
.hero-5-strip h5,
#hero-7 h5,
#hero-8 h5,
#hero-9 h5,
#hero-12 h5 {
  font-size: 0.85rem;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 25px;
}

#hero-2 h2,
#hero-11 h2 {
  font-size: 3.75rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0;
  margin-bottom: 25px;
}

#hero-2 h2 span,
#hero-6 h2 span,
#hero-9 h2 span,
#hero-11 h2 span  {
  font-weight: 900;
}

#hero-3 h2 {
  font-size: 5.15rem;
  font-weight: 900;
  line-height: 1.1;
  letter-spacing: -2px;
  margin-bottom: 25px;
}

#hero-3 h2 span {
  color: #ff005a;
}

#hero-4 h2 {
  font-size: 3.65rem;
  font-weight: 900;
  line-height: 1.25;
  text-transform: uppercase;
  letter-spacing: -0.5px;
  margin-bottom: 30px;
}

#hero-5 h3 {
  font-size: 2.15rem;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 20px;
}

#hero-5 h4 {
  font-size: 1.75rem;
  line-height: 1;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 15px;
}

.hero-5-strip h5 {
  line-height: 1;
  margin-bottom: 0;
}

#hero-6 h2 {
  font-size: 3.75rem;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 20px;
}

#hero-6 h5 {
  font-size: 0.95rem;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 30px;
}

#hero-7 h2 {
  font-size: 4rem;
  font-weight: 900;
  line-height: 1.15;
  text-transform: uppercase;
  letter-spacing: 0;
  margin-bottom: 25px;
}

#hero-8 h2 {
  font-size: 5.25rem;
  font-weight: 700;
  line-height: 1.15;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  margin-bottom: 25px;
}

#hero-9 h2 {
  font-size: 3.5rem;
  font-weight: 400;
  line-height: 1.25;
  text-transform: uppercase;
  letter-spacing: -1.5px;
  margin-bottom: 25px;
}

#hero-10 h2 {
  font-size: 3.85rem;
  text-transform: uppercase;
  font-weight: 900;
  line-height: 1.15;
  letter-spacing: 4px;
  margin-bottom: 25px;
}

#hero-11 h2 {
  font-size: 6rem;
  font-weight: 900;
  letter-spacing: -0.5px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

#hero-11 h3 {
  font-size: 1.65rem;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  display: inline-block;
  background-color: transparent;
  border: 2px solid #fff;
  padding: 12px 20px;
  margin-bottom: 30px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

#hero-12 h2 {
  font-size: 4rem;
  letter-spacing: -0.5px;
  text-transform: uppercase;
  margin-bottom: 25px;
}

#hero-12 h2 span {
  font-weight: 900;
  letter-spacing: -1px;
}

/*------------------------------------------*/
/*  Hero Paragraphs
/*------------------------------------------*/

#hero-1 .hero-txt p,
#hero-2 .caption p,
#hero-7 .caption p {
  padding-right: 5%;
}

#hero-4 .hero-txt p,
#hero-8 .caption p,
#hero-9 .caption p,
#hero-10 .caption p,
#hero-12 .hero-txt p {
  padding-right: 10%;
}

#hero-6 .caption p {
  padding: 0 18%;
}

/*------------------------------------------*/
/*  Hero Buttons
/*------------------------------------------*/

.hero-section .btn {
  margin-top: 20px;
}

/*------------------------------------------*/
/*  HERO FORM
/*------------------------------------------*/

.hero-form {
  padding: 45px 30px 40px;
  margin: 0 20px 0 50px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

.hero-form .col-md-12,
.hero-form .col-lg-12 {
  padding: 0;
}

.form-group {
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  Hero Form Text
/*------------------------------------------*/

.hero-form h4 {
  line-height: 1;
  margin-bottom: 30px;
}

/*------------------------------------------*/
/*  Hero Form Input
/*------------------------------------------*/

.hero-form .form-control,
.hero-form .custom-select {
  background-color: #fff;
  color: #333;
  height: 48px;
  font-size: 15px;
  line-height: 1;
  font-weight: 300;
  padding: 4px 15px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  box-shadow: none;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.hero-form .custom-select {
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
}

/*------------------------------------------*/
/*  Contact Form Placeholder
/*------------------------------------------*/

.hero-form .form-control::-moz-placeholder { color: #555; }
.hero-form .custom-select::-moz-placeholder { color: #555; }
.hero-form .form-control:-ms-input-placeholder { color: #555; }
.hero-form .custom-select:-ms-input-placeholder { color: #555; }
.hero-form .form-control::-webkit-input-placeholder { color: #555; }
.heroe-form .custom-select::-webkit-input-placeholder { color: #555; }

/*------------------------------------------*/
/*  Hero Form Input Focus
/*------------------------------------------*/

.hero-form .form-control:focus {
  background-color: #f0f0f0;
  border-color: #ccc;
  outline: 0px none;
  box-shadow: none;
}

/*------------------------------------------*/
/*  Hero Form Button
/*------------------------------------------*/

.hero-form .btn {
  display: block;
  width: 100%;
  height: 50px;
  margin-top: 0;
}

.hero-form .btn:focus {
  outline: 0px none;
  box-shadow: none;
}

/*------------------------------------------*/
/*  Hero Form Message
/*------------------------------------------*/

.hero-form label.error {
  color: #e74c3c;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 20px;
}

.hero-form-msg {
  width: 100%!important;
  display: block;
  text-align: center;
}

.hero-form .sending-msg {
  margin-top: 20px;
}

.hero-form .loading {
  color: #f0f0f0;
  font-size: 17px;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
}

/*------------------------------------------*/
/*   HERO IMAGE
/*------------------------------------------*/

.hero-1-img {
  margin-left: -20px;
}

.hero-1-img img {
  position: relative;
  max-width: none;
  display: inline-block;
}

.hero-12-img {
  background-image: url(../../../../../frontend/src/assets/images/hero-12-img.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 45%;
  left: 55%;
}