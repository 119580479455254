
#info-5 {
  background-image: url(../../../../../frontend/src/assets/images/info-5.jpg);
}

#info-6,
#info-7,
#info-8,
#info-9 {
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
}

#info-5 .container {
  overflow: hidden;
}

/*------------------------------------------*/
/*   INFO TEXT
/*------------------------------------------*/

#info-8 .txt-block {
  background-color: #00a3c8;
  padding: 50px 60px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  position: relative;
  margin-right: -100px;
  z-index: 3;
}

/*------------------------------------------*/
/*   INFO BOX TEXT
/*------------------------------------------*/

.ibox-1-txt {
  overflow: hidden;
  padding-left: 5px;
}

.ibox-1-txt h5 {
  margin-bottom: 10px;
}

.ibox-1-txt p {
  margin-bottom: 0;
}

/*------------------------------------------*/
/*   INFO TYPOGRAPHY
/*------------------------------------------*/

#info-8 .txt-block h4,
#info-8 .txt-block h5 {
  margin-bottom: 15px;
}

#info-8 .txt-block p {
  margin-bottom: 0;
}

.ibox-1 span {
  text-align: center;
  float: left;
  margin-top: 5px;
  margin-right: 25px;
}

/*------------------------------------------*/
/*   Info Table
/*------------------------------------------*/

#info-8 table {
  margin-top: 20px;
  margin-bottom: 20px;
}

#info-8 .table td, #info-8 .table th {
  font-size: 16px;
  padding: 18px 0;
  font-family: 'Lato', sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  border-top: none;
  border-bottom: 1px dashed rgba(255, 255, 255, .6);
}

.info-9-table {
  padding: 0 80px 0 40px;
}

.info-9-table h4 {
  margin-bottom: 20px;
}

.info-9-table h5 {
  margin-bottom: 15px;
}

.info-9-table p {
  margin-bottom: 0;
}

.info-9-table table {
  margin-top: 25px;
  margin-bottom: 25px;
}

.info-9-table .table td, .info-9-table .table th {
  color: #fff;
  font-size: 16px;
  padding: 18px 0;
  font-family: 'Lato', sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  border-top: none;
  border-bottom: 1px dashed rgba(255, 255, 255, .6);
}

.info-9-table .last-tr td, .info-9-table .table .last-tr th,
#info-8 .table .last-tr td, #info-8 .table .last-tr th {
  border-bottom: none;
}

/*------------------------------------------*/
/*   INFO IMAGE
/*------------------------------------------*/

.info-1-img {
  text-align: center;
  position: relative;
  left: -50%;
  margin-bottom: 40px;
}

.info-2-img {
  text-align: center;
  position: relative;
  margin-bottom: 40px;
}

.info-1-img img,
.info-2-img img {
  max-width: none;
  display: inline-block;
}

.info-6-img {
  background-image: url(../../../../../frontend/src/assets/images/info-6.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 48%;
  left: 0;
}

.info-7-img {
  background-image: url(../../../../../frontend/src/assets/images/info-7.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  left: 50%;
}

.info-8-img {
  background-image: url(../../../../../frontend/src/assets/images/info-8.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 60%;
  left: 40%;
}

.info-9-img {
  background-image: url(../../../../../frontend/src/assets/images/info-9.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  left: 50%;
}

