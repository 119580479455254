#breadcrumb {
  background-image: url(../../../../../frontend/src/assets/images/breadcrumb.jpg);
  background-position: center center;
}

.breadcrumb-holder {
  padding-top: 40px;
  padding-bottom: 45px;
  padding-left: 15px;
}

.breadcrumb {
  padding: 0;
  margin-bottom: 0;
  background-color: transparent;
  border-radius: 0;
}

/*------------------------------------------*/
/*  BREADCRUMB TYPOGRAPHY
/*------------------------------------------*/

.breadcrumb-holder h4 {
  line-height: 1;
  font-weight: 900;
  letter-spacing: 0;
  margin-top: 10px;
  margin-bottom: 0;
}

.breadcrumb-item a {
  color: #333;
  font-size: 0.95rem;
  font-weight: 400;
  text-decoration: underline;
}

.breadcrumb-item a:hover {
  color: #00a3c8;
}

.breadcrumb-item.active {
  color: #666;
  font-size: 0.95rem;
  font-weight: 400;
  margin-top: 2px;
}

.breadcrumb-item + .breadcrumb-item::before {
  font-size: 1.05rem;
  display: inline-block;
  padding-right: 8px;
  margin-top: -2px;
  color: #858585;
  content: "\00bb";
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 10px;
}