.brands-section {
  padding-top: 60px;
  padding-bottom: 30px;
}

.brands-section p {
  color: #888;
  font-size: 1.1rem;
  font-weight: 400;
  margin-bottom: 30px;
}

/*------------------------------------------*/
/*  BRANDS LOGO HOLDER
/*------------------------------------------*/

.brand-logo {
  margin-bottom: 30px;
}

.brands-holder img {
  padding: 0 2px;
}