#banner-1 {
  background-image: url(../../../../../frontend/src/assets/images/banner-1.jpg);
  padding-top: 120px;
  padding-bottom: 120px;
}

#banner-2 .bg-image.bg-inner {
  background-image: url(../../../../../frontend/src/assets/images/banner-2.jpg);
  position: relative;
  z-index: 1;
}

#banner-4 {
  padding-top: 120px;
  padding-bottom: 120px;
  position: relative;
  z-index: 1;
}

#banner-6 {
  background-image: url(../../../../../frontend/src/assets/images/banner-6.jpg);
  padding-top: 60px;
  padding-bottom: 60px;
}

#banner-7 {
  background-image: url(../../../../../frontend/src/assets/images/banner-7.jpg);
  padding-top: 120px;
  padding-bottom: 120px;
}

#banner-8 {
  background-image: url(../../../../../frontend/src/assets/images/banner-8.jpg);
  padding-top: 100px;
  padding-bottom: 100px;
}

#banner-5 .container {
  overflow: hidden;
}

/*------------------------------------------*/
/*   BANNER TEXT
/*------------------------------------------*/

#banner-4 .banner-txt {
  padding-left: 60px;
}

/*------------------------------------------*/
/*   BANNER TYPOGRAPHY
/*------------------------------------------*/

#banner-1 .banner-txt h2 {
  line-height: 1.15;
  letter-spacing: -0.5px;
  margin-bottom: 3px;
}

#banner-3 .banner-txt h2 {
  line-height: 1.25;
  letter-spacing: -0.5px;
  margin-bottom: 0;
}

#banner-4 .banner-txt h2 {
  letter-spacing: -1px;
}

#banner-6 .banner-txt h4 {
  letter-spacing: 0px;
  margin-bottom: 3px;
}

#banner-7 .banner-txt h2 {
  line-height: 1.25;
  letter-spacing: 0;
  margin-bottom: 25px;
}

#banner-8 h3:after {
  display: block;
  content: "";
  height: 2px;
  width: 100px;
  background: #fff;
  margin: 30px auto;
  position: relative;
}

#banner-8 h3 {
  margin-top: 30px;
}

#banner-8 h4 {
  letter-spacing: 0.5px;
}

#banner-8 h2 {
  font-weight: 900;
  letter-spacing: 1.5px;
  margin-bottom: 0;
}

/* Banner Phone Number */
#banner-3 .banner-txt h3.h3-md,
#banner-4 .banner-txt h3.h3-xl {
  font-weight: 900;
  letter-spacing: 0.5px;
  margin-top: 20px;
  margin-bottom: 25px;
}

#banner-1 .banner-txt p {
  margin-top: 30px;
  padding-right: 10%;
}

#banner-2 .banner-txt p {
  margin-top: 25px;
  margin-bottom: 30px;
}

/*------------------------------------------*/
/*   Banner Details
/*------------------------------------------*/

#banner-1 .banner-details h5 {
  font-size: 2.5rem;
  line-height: 2rem!important;
  font-weight: 900;
  letter-spacing: -1px;
  margin-top: 20px;
  margin-bottom: 0;
  margin-left: 6px;
  display: inline-block;
}

#banner-1 .banner-details span {
  color: #444;
  font-size: 1.1rem;
  font-family: 'Lato', sans-serif;
  line-height: 2.15rem!important;
  font-weight: 400;
  position: relative;
  top: -6px;
}

#banner-1 .banner-txt p.p-md {
  color: #888;
  font-size: 0.875rem;
  line-height: 1;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
  position: relative;
  top: -7px;
}

/*------------------------------------------*/
/*   BANNER ICON
/*------------------------------------------*/

#banner-6 span {
  text-align: center;
  float: left;
  margin-right: 15px;
  margin-top: 10px;
}

#banner-6 .banner-txt {
  overflow: hidden;
  padding-left: 10px;
}

/*------------------------------------------*/
/*   BANNER IMAGE
/*------------------------------------------*/

.banner-2-img {
  text-align: center;
  margin-top: -80px;
  position: relative;
  z-index: 999;
}

.banner-3-img {
  text-align: center;
  position: relative;
}

.banner-3-img img {
  max-width: none;
  display: inline-block;
}

.banner-4-img {
  background-image: url(../../../../../frontend/src/assets/images/banner-4.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  left: 0;
}