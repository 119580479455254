.blog-page-pagination {
  margin-top: 70px;
  padding-top: 60px;
}

.page-link {
  color: #666;
  font-weight: 500;
  padding: .65rem .95rem;
  margin: 0 5px;
  background-color: #fff;
  border-color: #e5e5e5;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  border-radius: 6px;
}

.page-item:first-child .page-link {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.page-item:last-child .page-link {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.page-item.disabled .page-link {
  color: #aaa;
  background-color: #fff;
  border-color: #e5e5e5;
}

.next-page.page-link1 {
  margin-left: 30px;
}

.page-link:hover,
.page-link:focus,
.page-item.active .page-link {
  background-color: #5496ff;
  color: #fff;
  border-color: #5496ff;
  -webkit-box-shadow: 0 8px 8px rgba(0, 0, 0, .15);
  -moz-box-shadow: 0 8px 8px rgba(0, 0, 0, .15);
  box-shadow: 0 8px 8px rgba(0, 0, 0, .15);
}

.primary-theme .page-link:hover,
.primary-theme .page-link:focus,
.primary-theme .page-item.active .page-link {
  background-color: #00a3c8;
  border-color: #00a3c8;
}