#statistic-1 {
  background-image: url(../../../../../frontend/src/assets/images/statistic.jpg);
  padding-top: 80px;
  padding-bottom: 40px;
}

#statistic-2 {
  padding-top: 80px;
  padding-bottom: 40px;
}

.statistic-block {
  text-align: center;
  margin-bottom: 40px;
}

#statistic-2 .statistic-block {
  text-align: center;
  background-color: #fff;
  border: 1px solid #eee;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  padding: 40px 20px;
  margin-bottom: 40px;
}

#statistic-2.bg-lightgrey .statistic-block {
  background-color: #fff;
  border: 1px solid #f5f5f5;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.statistic-holder .statistic-block {
  text-align: left;
  margin-bottom: 0;
}

.statistic-holder {
  margin-top: 45px;
}

/*------------------------------------------*/
/*  STATISTIC BLOCK
/*------------------------------------------*/

.statistic-block h5 {
  font-size: 2.85rem;
  line-height: 1;
  font-weight: 900;
  letter-spacing: 0.5px;
  margin-top: 20px;
  margin-bottom: 15px;
}

#statistic-2 .statistic-block h5 {
  font-size: 2.35rem;
  line-height: 1;
  font-weight: 900;
  letter-spacing: 0.5px;
  margin-top: 25px;
  margin-bottom: 10px;
}

.statistic-holder .statistic-block h5 {
  font-size: 2rem;
  line-height: 1;
  font-weight: 900;
  letter-spacing: 0.5px;
  margin-top: 22px;
  margin-bottom: 10px;
}

.statistic-block p {
  font-size: 1.05rem;
  font-family: 'Lato', sans-serif;
  margin-bottom: 0;
}

#statistic-2 .statistic-block p {
  color: #757575;
  font-size: 1rem;
}

.statistic-holder .statistic-block p {
  color: #757575;
  font-size: 0.925rem;
}

/*------------------------------------------*/
/*   STATISTIC IMAGE
/*------------------------------------------*/

.statistic-img {
  text-align: center;
  position: relative;
  margin-bottom: 40px;
}

.statistic-img img {
  max-width: none;
  display: inline-block;
}