
#about-1,
#about-2,
#about-3 {
  position: relative;
  margin-top: -120px;
  z-index: 99;
}

#about-1 .col-lg-3,
#about-2 .col-lg-4 {
  padding: 0;
}

#about-1 .row,
#about-2 .row {
  margin-left: 0;
  margin-right: 0;
}

.abox-2-holder,
.abox-3-holder {
  background-color: #fff;
  border: 1px solid #eee;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
}

.abox-3-holder {
  border: 1px solid #f5f5f5;
  padding: 50px 15px;
}

#about-5 .container,
#about-6 .container {
  overflow: hidden;
}

/*------------------------------------------*/
/*  ABOUT BOX
/*------------------------------------------*/

#abox-1 { background-color: #36a8eb; border-radius: 6px 0 0 6px; }
#abox-2 { background-color: #088edd; }
#abox-3 { background-color: #1977cc; }
#abox-4 { background-color: #1063af;  border-radius: 0 6px 6px 0; }

.abox-1 {
  height: 300px;
  padding: 45px 30px;
  overflow: hidden;
}

.abox-2 {
  padding: 45px 30px 40px;
  overflow: hidden;
}

.abox-3 {
  padding: 0 30px;
}

/*------------------------------------------*/
/*   ABOUT BOX TEXT
/*------------------------------------------*/

.abox-4-txt {
  margin-top: 25px;
}

/*------------------------------------------*/
/*  About Box Typography
/*------------------------------------------*/

.abox-1 h5,
.abox-2 h5 {
  line-height: 1;
  margin-bottom: 25px;
}

.abox-2 h5 {
  margin-bottom: 30px;
}

.abox-3 h5,
.abox-4 h5  {
  font-size: 1.05rem;
  line-height: 1;
  font-weight: 900;
  letter-spacing: 0.25px;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.abox-1 p,
.abox-2 p,
.abox-3 p,
.abox-4 p {
  font-size: 0.925rem;
  margin-bottom: 0;
}

.abox-3 p.blue-color {
  font-size: 0.975rem;
  font-weight: 400;
  margin-bottom: 15px;
}

.abox-1 h5.emergency-call {
  line-height: 1.15rem!important;
}

.abox-2 h4.emergency-call {
  line-height: 1.5rem!important;
}

.abox-1 i,
.abox-2 i {
  font-size: 1.15rem;
  line-height: 1!important;
  margin-right: 3px;
  position: relative;
  top: -2px;
}

.abox-2 i {
  font-size: 1.5rem;
  margin-right: 5px;
}

/*------------------------------------------*/
/*  About Box Table
/*------------------------------------------*/

.abox-4-table {
  background-color: #f9f9f9;
  border: 1px solid #f5f5f5;
  padding: 50px 30px 25px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.abox-4-table.blue-table {
  background-color: #00a3c8;
  border: 1px solid #00a3c8;
  color: #fff;
  box-shadow: none;
}

.abox-4-table h5,
.abox-4-table h4 {
  margin-bottom: 15px;
}

.abox-4-table.blue-table h5,
.abox-4-table.blue-table h4,
.abox-4-table.blue-table table {
  color: #fff;
}

.abox-1 table,
.abox-2 table {
  margin-bottom: 0;
}

.abox-4-table table {
  margin-top: 15px;
  margin-bottom: 10px;
}

.abox-1 .table td, .abox-1 .table th {
  font-size: 13px;
  padding: 13px 0;
  border-top: none;
  border-bottom: 1px dashed rgba(255, 255, 255, .6);
}

.abox-2 .table td, .abox-2 .table th {
  font-size: 15px;
  padding: 13px 0;
  border-top: none;
  border-bottom: 1px dashed #aaa;
}

.abox-4-table .table td, .abox-4 .table th {
  font-size: 16px;
  padding: 16px 0;
  border-top: none;
  border-bottom: 1px dashed #aaa;
}

.abox-4-table.blue-table .table td,
.abox-4-table.blue-table .table th {
  border-bottom: 1px dashed rgba(255, 255, 255, .6);
}

.abox-2 .table .last-tr td, .abox-2 .table .last-tr th,
.abox-1 .table .last-tr td, .abox-1 .table .last-tr th,
.abox-4-table .table .last-tr td, .abox-4-table .table .last-tr th {
  border-bottom: none;
}

td.text-right {
  font-weight: 500;
}
