#gallery-3 {
  padding-top: 60px;
  padding-bottom: 80px;
}

#gallery-3 .section-title {
  margin-bottom: 30px;
}

.gallery-items-list {
  margin: 0 -10px;
  padding: 0;
}

#gallery-1 .gallery-items-list {
  margin: 0;
}

/*------------------------------------------*/
/*    FILTER BUTTONS
/*------------------------------------------*/

.gallery-filter button {
  background-color: transparent;
  color: #888;
  font-size: 1rem;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  letter-spacing: -0;
  margin: 0 30px;
  padding: 0.5rem 0.1rem;
  border: none;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  outline: 0px none;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

/*------------------------------------------*/
/*     Filter Button Hover
/*------------------------------------------*/

.gallery-filter button:hover,
.gallery-filter button:focus {
  background-color: transparent;
  color: #00a3c8;
  border-bottom: 2px solid #00a3c8;
  outline: 0px none;
}

/*------------------------------------------*/
/*    Filter Button Active
/*------------------------------------------*/

.gallery-filter button.is-checked {
  color: #00a3c8;
  cursor: default;
  border-bottom: 2px solid #00a3c8;
  outline: 0px none;
}

.gallery-item {
  position: relative;
  text-align: center;
  padding: 0 10px;
  margin-bottom: 20px;
}

#gallery-1 .gallery-item {
  padding: 0;
  margin-bottom: 0;
}

.masonry-wrap .gallery-item {
  text-align: center;
  width: 33.33%;
  padding: 0 10px;
  margin-bottom: 20px;
}

/*------------------------------------------*/
/*    Image Zoom
/*------------------------------------------*/

.image-zoom {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.image-zoom a {
  width: 54px;
  height: 54px;
  font-size: 24px;
  line-height: 50px!important;
  color: #fff;
  background-color: transparent;
  border: 2px solid #fff;
  display: inline-block;
  -webkit-transform: translate3d(0, 20px, 0);
  transform: translate3d(0, 20px, 0);
  opacity: 0;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

.image-zoom a:hover {
  color: #757575;
  background-color: #fff;
  border: 2px solid #fff;
}

/*------------------------------------------*/
/*    Image Hover Overlay
/*------------------------------------------*/

.hover-overlay {
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;
}

.hover-overlay img {
  transform: scale(1.1);
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
  -moz-transform: scale(1.1);
  overflow: hidden;
  -webkit-transition: transform 400ms;
  -moz-transition: transform 400ms;
  -o-transition: transform 400ms;
  transition: transform 400ms;
}

/*------------------------------------------*/
/*    Overlay Background
/*------------------------------------------*/

.item-overlay {
  opacity: 0;
  -moz-opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 163, 200, 0.85);
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.item-overlay::before,
.item-overlay::after {
  position: absolute;
  top: 25px;
  right: 25px;
  bottom: 25px;
  left: 25px;
  content: '';
  opacity: 0;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

.item-overlay::before {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: scale(0,1);
  transform: scale(0,1);
}

.item-overlay::after {
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
  -webkit-transform: scale(1,0);
  transform: scale(1,0);
}

/*------------------------------------------*/
/*    Image Hover Effect
/*------------------------------------------*/

.gallery-item:hover img {
  transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  -o-transform: scale(1);
  -moz-transform: scale(1);
}

.gallery-item:hover .item-overlay {
  opacity: 1;
  -moz-opacity: 1;
}

.gallery-item:hover .item-overlay::before,
.gallery-item:hover .item-overlay::after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.gallery-item:hover a {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}