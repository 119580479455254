#scrollUp {
  display: none;
  width: 45px;
  height: 45px;
  position: fixed;
  bottom: 20px;
  right: 30px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background-image: url(../../../../../frontend/src/assets/images/back-to-top.png);
  background-repeat: no-repeat;
  background-position: 50% 48%;
  background-color: rgba(10, 10, 10, 0.65);
  -webkit-transition: all 250ms linear;
  -moz-transition: all 250ms linear;
  transition: all 250ms linear;
}

#scrollUp:hover {
  background-color: rgba(0, 165, 200, 0.95);
}

nav a#pull {
  display: none;
}