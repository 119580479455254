#sidebar h5.h5-sm {
  padding-bottom: 20px;
  margin-bottom: 25px;
  border-bottom: 1px solid #ddd;
}

/*------------------------------------------*/
/*   SIDEBAR SEARCH FORM
/*------------------------------------------*/

#search-field .form-control {
  background-color: #f0f0f0;
  height: 54px;
  padding-left: 20px;
  border: none;
  -webkit-border-radius: 6px 0 0 6px;
  -moz-border-radius: 6px 0 0 6px;
  border-radius: 6px 0 0 6px;
}

#search-field .btn {
  background-color: #f0f0f0;
  color: #777;
  border: none;
  height: 54px;
  padding: 0 18px;
  margin-top: 0;
  -webkit-box-shadow: 0 0 0;
  -moz-box-shadow: 0 0 0;
  box-shadow: 0 0 0;
  -webkit-border-radius: 0px 6px 6px 0;
  -moz-border-radius: 0px 6px 6px 0;
  border-radius: 0px 6px 6px 0;
}

#search-field .form-control:focus {
  border-color: #999;
  outline: 0px none;
  box-shadow: none;
}

/*------------------------------------------*/
/*   SIDEBAR BLOG CATEGORIES
/*------------------------------------------*/

ul.blog-category-list > li {
  padding: 12px 0;
  border-bottom: 1px dashed #c0c0c0;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

ul.blog-category-list > li:first-child {
  padding: 0 0 12px;
}

ul.blog-category-list > li:last-child {
  padding: 12px 0 0;
  border-bottom: none;
}

ul.blog-category-list > li a {
  color: #555;
}

ul.blog-category-list > li a i {
  font-size: 0.875rem;
  margin-right: 5px;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

ul.blog-category-list > li span {
  color: #444;
  font-weight: 500;
}

ul.blog-category-list li:hover {
  padding-left: 12px;
}

/*------------------------------------------*/
/*   SIDEBAR POPULAR POSTS
/*------------------------------------------*/

.popular-posts li {
  padding: 14px 0;
  border-bottom: 1px dashed #c0c0c0;
}

.popular-posts li:first-child {
  padding: 0 0 14px;
}

.popular-posts li:last-child {
  padding: 14px 0 0;
  border-bottom: 0;
}

.popular-posts img {
  text-align: center;
  float: left;
}

.post-summary {
  overflow: hidden;
  padding-left: 20px;
}

/*------------------------------------------*/
/*  SIDEBAR POPULAR POSTS TYPOGRAPHY
--------------------------------------------*/

.post-summary a {
  color: #555;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.popular-posts .post-summary a:hover {
  text-decoration: underline;
}

.popular-posts p {
  color: #888;
  font-size: 0.875rem;
  margin-top: 6px;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*   SIDEBAR TAGS CLOUD
/*------------------------------------------*/

.badge {
  font-size: 0.75rem;
  font-weight: 600;
  color: #999;
  border: none;
  background-color: #fff;
  border: 2px solid #ccc;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  border-radius: 6px;
  margin-bottom: 8px;
  padding: 4px 14px;
  margin-right: 2px;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

.badge a {
  color: #999!important;
  font-size: 0.925rem;
  font-weight: 400;
  line-height: 1.42857;
}

.badge:hover {
  background-color: #00a3c8;
  border-color: #00a3c8;
}

.badge:hover a {
  color: #fff!important;
}

/*------------------------------------------*/
/*  SIDEBAR TABLE
/*------------------------------------------*/

.sidebar-table {
  background-color: #f9f9f9;
  border: 1px solid #f5f5f5;
  padding: 50px 30px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.sidebar-table.blue-table {
  background-color: #00a3c8;
  border: 1px solid #00a3c8;
  color: #fff;
  box-shadow: none;
}

.sidebar-table h5,
.sidebar-table h4 {
  margin-bottom: 15px;
}

.sidebar-table h5.h5-xs {
  margin-top: 20px;
}

.sidebar-table p {
  margin-bottom: 0;
}

.sidebar-table.blue-table h5,
.sidebar-table.blue-table h4,
.sidebar-table.blue-table table {
  color: #fff;
}

.sidebar-table table {
  margin-top: 15px;
  margin-bottom: 0;
}

.sidebar-table .table td, .sidebar-table .table th {
  font-size: 16px;
  padding: 16px 0;
  border-top: none;
  border-bottom: 1px dashed #aaa;
}

.sidebar-table.blue-table .table td,
.sidebar-table.blue-table .table th {
  border-bottom: 1px dashed rgba(255, 255, 255, .6);
}

.sidebar-table .table .last-tr td,
.sidebar-table .table .last-tr th {
  border-bottom: none;
}

/*------------------------------------------*/
/*  SIDEBAR TIMETABLE
/*------------------------------------------*/

.sidebar-timetable {
  border: 1px solid #ccc;
  padding: 40px 30px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

/*------------------------------------------*/
/*  SIDEBAR TEXT WIDGET
/*------------------------------------------*/

.txt-widget-avatar {
  display: inline-block;
  float: left;
}

/*------------------------------------------*/
/*   Avatar
/*------------------------------------------*/

.txt-widget-avatar img {
  width: 100px;
  height: 100px;
  display: inline-block;
  margin: 0 15px 0 0;
}

/*------------------------------------------*/
/*   Avatar Data
/*------------------------------------------*/

.txt-widget-data h5 {
  font-size: 1.2rem;
  line-height: 1;
  padding-top: 10px;
  margin-bottom: 5px;
}

.txt-widget-data span {
  color: #666;
  font-size: 0.915rem;
}

.txt-widget-data p {
  font-size: 1.25rem;
  line-height: 1;
  font-weight: 900;
  margin-top: 4px;
  margin-bottom: 0;
}