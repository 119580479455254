.blog-post {
  margin-bottom: 40px;
}

#blog-page .blog-post {
  margin-bottom: 70px;
}

/*------------------------------------------*/
/*    BLOG TYPOGRAPHY
/*------------------------------------------*/

.blog-post-txt span {
  font-size: 0.95rem;
  font-weight: 400;
  line-height: 1;
}

.sblog-post-txt span,
#blog-page .blog-post-txt span {
  font-size: 1rem;
}

.blog-post-txt span span,
.sblog-post-txt  span span,
#blog-page .blog-post-txt span span {
  font-weight: 500;
}

.blog-post-txt h5 {
  margin-top: 5px;
  line-height: 1.35;
  margin-top: 20px;
  margin-bottom: 2px;
}

.blog-post-txt p {
  font-size: 0.975rem;
  line-height: 1.5;
  margin-top: 5px;
  margin-bottom: 0;
}

#blog-page .blog-post-txt p {
  font-size: 1rem;
  margin-top: 10px;
}

.blog-post-txt h5 a:hover {
  color: #666;
  text-decoration: underline;
}

.sblog-post-txt  h5.h5-md {
  margin-top: 20px;
  margin-bottom: 20px;
}

/*------------------------------------------*/
/*   More Posts Link
/*------------------------------------------*/

.all-posts-btn {
  margin-top: 10px;
}

/*------------------------------------------*/
/*  SINGLE BLOG POST
/*------------------------------------------*/

.post-share-links {
  margin-top: 70px;
  padding-top: 70px;
  border-top: 1px solid #e5e5e5;
}

/*------------------------------------------*/
/*  POST INNER IMAGE
/*------------------------------------------*/

.post-inner-img {
  margin-top: 40px;
  margin-bottom: 40px;
}

.post-inner-img h4 {
  line-height: 1.35;
  margin-bottom: 30px;
}

.post-inner-img h5 {
  font-size: 1rem;
  line-height: 1;
  margin-bottom: 3px;
}

.post-inner-img p {
  color: #999;
  font-size: 0.95rem;
  font-weight: 500;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  POST TAGS
/*------------------------------------------*/

.post-tags-list {
  margin-bottom: 40px;
}

.post-tags-list span a {
  color: #999;
  font-size: 0.925rem;
  font-weight: 400;
  line-height: 1.42857;
  border: 2px solid #ccc;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  border-radius: 6px;
  padding: 6px 16px;
  margin-right: 4px;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.post-tags-list span a:hover {
  background-color: #00a3c8;
  border-color: #00a3c8;
  color: #fff;
}

/*------------------------------------------*/
/*  POST SHARE ICONS
/*-----------------------------------------*/

.share-social-icons {
  display: inline-block;
  padding-left: 0;
}

.share-social-icons li {
  width: auto !important;
  display: inline-block !important;
  vertical-align: top;
  clear: none !important;
  padding: 0;
}

a.share-ico {
  width: 100px;
  height: 36px;
  font-size: 1rem;
  line-height: 36px !important;
  text-align: center;
  margin-right: 10px;
  display: block;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.share-social-icons i {
  font-size: 14px;
  margin-right: 8px;
}

a.share-ico.ico-like { background-color: #f2f2f2; color: #333; }
a.share-ico.ico-facebook { background-color: #3b5998; color: #fff; }
a.share-ico.ico-twitter { background-color: #00a9ed; color: #fff; }
a.share-ico.ico-google-plus { background-color: #dd4b39; color: #fff; }
a.share-ico.ico-pinterest { background-color: #cb2027; color: #fff; }

/*------------------------------------------*/
/*  ABOUT POST AUTHOR
/*------------------------------------------*/

.author-senoff {
  background-color: #f8f9fb;
  padding: 45px;
  margin-top: 80px;
  margin-bottom: 80px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.author-senoff img {
  width: 100px;
  height: 100px;
  float: left;
  text-align: center;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}

.author-senoff-txt {
  overflow: hidden;
  padding-left: 30px;
}

.author-senoff-txt h5 {
  margin-bottom: 15px;
}

.author-senoff-txt p {
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  SINGLE POST COMMENTS
/*------------------------------------------*/

.single-post-comments {
  margin-top: 30px;
  margin-bottom: 60px;
  padding: 0 3%;
}

.related-posts h5.h5-md,
.single-post-comments h5.h5-md {
  padding-bottom: 30px;
  margin-bottom: 60px;
  border-bottom: 1px solid #e5e5e5;
}

.single-post-comments img {
  width: 55px;
  height: 55px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}

.comment-meta {
  margin-bottom: 10px;
}

.comment-meta h5 {
  font-size: 1rem;
  line-height: 1.1;
  margin-bottom: 2px;
}

.comment-date {
  color: #888;
  font-size: 14px;
  margin-bottom: 5px;
}

.btn-reply {
  margin-left: 10px;
}

.btn-reply a {
  color:  #888;
  font-size: 14px;
}

.btn-reply a i {
  font-size: 13px;
  margin-right: 1px;
}

.btn-reply a:hover {
  color:  #000;
}

.single-post-comments hr {
  margin-top: 35px;
  margin-bottom: 35px;
}

/*------------------------------------------*/
/*    SINGLE POST COMMENT FORM
/*------------------------------------------*/

#leave-comment h5.h5-md {
  margin-bottom: 3px;
}

.comment-form {
  position: relative;
  margin-top: 50px;
}

.comment-form p {
  color: #333;
  font-size: 15px;
  line-height: 15px;
  font-weight: 700;
  margin-bottom: 10px;
  padding-left: 5px;
  display: block;
}

.comment-form .form-control {
  height: 54px;
  background-color: #fff;
  border: 1px solid #ccc;
  color: #333;
  font-size: 1rem;
  font-weight: 300;
  padding: 0 20px;
  margin-bottom: 22px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.comment-form .form-control:focus {
  border-color: #16a2e0;
  outline: 0px none;
  box-shadow: none;
}

.comment-form textarea { min-height: 200px; }
.comment-form textarea.form-control {
  padding: 20px;
}

/*------------------------------------------*/
/*  Comment Form Button
/*------------------------------------------*/

.hero-form .btn:focus {
  outline: 0px none;
  box-shadow: none;
}

/*------------------------------------------*/
/*  Comment Form Message
/*------------------------------------------*/

.comment-form label.error {
  color: #e74c3c;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 20px;
}

.comment-form-msg {
  width: 100%!important;
  display: block;
  text-align: center;
}

.comment-form .sending-msg {
  margin-top: 20px;
}

.comment-form .loading {
  color: #666;
  font-size: 17px;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
}