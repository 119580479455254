#footer-1 .footer-box {
  padding-left: 10%;
}

.footer.bg-image {
  background-image: url(../../../../../frontend/src/assets/images/footer-bg.jpg);
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

/*------------------------------------------*/
/*   FOOTER TYPOGRAPHY
/*------------------------------------------*/

.footer h5.h5-xs {
  line-height: 1;
  letter-spacing: 0;
  margin-bottom: 25px;
}

#footer-2 .footer-info p {
  font-weight: 400;
  margin-bottom: 3px;
}

.footer-box p {
  font-size: 0.975rem;
  margin-bottom: 3px;
}

.footer-box p span {
  color: #444;
  font-size: 0.925rem;
  font-weight: 500;
}

.footer-box h5.h5-xl {
  font-weight: 900;
  letter-spacing: 0.5px;
  margin-bottom: 0;
}

#footer-2 p.foo-email {
  margin-top: 30px;
  margin-bottom: 5px;
}

p.foo-email a {
  font-weight: 500;
  text-decoration: underline;
}

p.footer-copyright {
  font-size: 0.95rem;
  margin-bottom: 0;
}

p.footer-copyright span {
  font-weight: 700;
}

.footer.bg-image h5.h5-xs,
.footer.bg-image p.foo-email a {
  color: #fff;
}

.footer.bg-image .footer-box p,
.footer.bg-image .footer-info p,
.footer.bg-image .footer-links li a,
.footer.bg-image p.footer-copyright {
  color: #c5d1dc;
}

.footer.bg-image .footer-box p span,
.footer.bg-image p.footer-copyright span {
  color: #fff;
}

/*------------------------------------------*/
/*   FOOTER LINKS
/*------------------------------------------*/

.footer-links li {
  font-size: 0.975rem;
  width: auto !important;
  display: block !important;
  vertical-align: top;
  clear: none !important;
  margin: 0 0 9px 0;
  padding: 0;
}

.footer-links li a:hover {
  color: #000;
  text-decoration: underline;
}

/*------------------------------------------*/
/*   FOOTER SOCIAL LINKS
/*------------------------------------------*/

.foo-socials {
  display: inline-block;
  padding-left: 0;
  margin: 0 auto 0;
}

.foo-socials li {
  width: auto !important;
  display: inline-block !important;
  vertical-align: top;
  clear: none !important;
  margin: 0 0 8px 0;
  padding: 0;
}

.foo-socials a {
  display: block;
  background-color: transparent;
  border: 2px solid #666;
  width: 36px;
  height: 36px;
  color: #666;
  font-size: 16px;
  line-height: 34px!important;
  margin-right: 4px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

.footer.bg-image .foo-socials a {
  border: 2px solid #c5d1dc;
  color: #c5d1dc;
}

.foo-socials a.ico-facebook:hover { background-color: #3b5998; border-color: #3b5998; color: #fff; }
.foo-socials a.ico-twitter:hover { background-color: #00a9ed; border-color: #00a9ed; color: #fff; }
.foo-socials a.ico-instagram:hover { background-color: #dc3c59; border-color: #dc3c59; color: #fff; }
.foo-socials a.ico-dribbble:hover { background-color: #d92d84; border-color: #d92d84; color: #fff; }
.foo-socials a.ico-behance:hover { background-color: #2473f6; border-color: #2473f6; color: #fff; }
.foo-socials a.ico-pinterest:hover { background-color: #ac281a; border-color: #ac281a; color: #fff; }
.foo-socials a.ico-linkedin:hover { background-color: #015886; border-color: #015886; color: #fff; }
.foo-socials a.ico-google-plus:hover { background-color: #cd1111; border-color: #cd1111; color: #fff; }
.foo-socials a.ico-youtube:hover { background-color: #cd1b20; border-color: #cd1b20; color: #fff; }
.foo-socials a.ico-tumblr:hover { background-color: #3a5976; border-color: #3a5976; color: #fff; }
.foo-socials a.ico-vk:hover { background-color: #3b5998; border-color: #3b5998; color: #fff; }
.foo-socials a.ico-yahoo:hover { background-color: #7b0099; border-color: #7b0099; color: #fff; }
.foo-socials a.ico-yelp:hover { background-color: #d32323; border-color: #d32323; color: #fff; }

/*------------------------------------------*/
/*   FOOTER NEWSLETTER FORM
/*------------------------------------------*/

.footer-form .form-control {
  height: 48px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-right: none;
  color: #333;
  font-size: 1rem;
  padding: 0 15px;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

/*------------------------------------------*/
/*    Newsletter Form Input Focus
/*------------------------------------------*/

.footer-form .form-control:focus {
  border: 1px solid #ccc;
  border-right: none;
  outline: 0;
  box-shadow: none;
}

/*------------------------------------------*/
/*    Newsletter Form Button
/*------------------------------------------*/

.footer-form .btn {
  height: 48px;
  color: #aaa;
  background-color: #fff;
  border: 1px solid #ccc;
  border-left: none;
  font-size: 1.45rem;
  line-height: 1!important;
  padding: 0 15px;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}

/*------------------------------------------*/
/*    Newsletter Form Notification
/*------------------------------------------*/

.footer-form .form-notification {
  font-size: 0.925rem;
  font-weight: 400;
  line-height: 1;
  margin-top: 15px;
  margin-left: 5px;
}

/*------------------------------------------*/
/*   BOTTOM FOOTER
/*------------------------------------------*/

.bottom-footer {
  border-top: 1px solid #ddd;
  margin-top: 20px;
  padding-top: 40px;
}

.footer.bg-image .bottom-footer {
  border-top: 1px solid #16739d;
}