html {
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
}

body {
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #333;
  font-weight: 300;
}

/*------------------------------------------------------------------*/
/*  IE10 in Windows 8 and Windows Phone 8 Bug fix
/*-----------------------------------------------------------------*/

@-webkit-viewport { width: device-width; }
@-moz-viewport { width: device-width; }
@-ms-viewport { width: device-width; }
@-o-viewport { width: device-width; }
@viewport { width: device-width; }

/*------------------------------------------*/
/*  PAGE CONTENT
/*------------------------------------------*/

#page { overflow: hidden; }

/*------------------------------------------*/
/*  SPACING & INDENTS
/*------------------------------------------*/

.wide-100 { padding-top: 100px; padding-bottom: 100px; }
.wide-90 { padding-top: 100px; padding-bottom: 90px; }
.wide-80 { padding-top: 100px; padding-bottom: 80px; }
.wide-70 { padding-top: 100px; padding-bottom: 70px; }
.wide-60 { padding-top: 100px; padding-bottom: 60px; }
.wide-50 { padding-top: 100px; padding-bottom: 50px; }
.wide-40 { padding-top: 100px; padding-bottom: 40px; }
.wide-30 { padding-top: 100px; padding-bottom: 30px; }
.wide-20 { padding-top: 100px; padding-bottom: 20px; }

/*------------------------------------------*/
/*  Margin Top
/*------------------------------------------*/

.mt-100 { margin-top: 100px; }
.mt-90 { margin-top: 90px; }
.mt-80 { margin-top: 80px; }
.mt-70 { margin-top: 70px; }
.mt-60 { margin-top: 60px; }
.mt-50 { margin-top: 50px; }
.mt-45 { margin-top: 45px; }
.mt-40 { margin-top: 40px; }
.mt-35 { margin-top: 35px; }
.mt-30 { margin-top: 30px; }
.mt-25 { margin-top: 25px; }
.mt-20 { margin-top: 20px; }
.mt-15 { margin-top: 15px; }
.mt-10 { margin-top: 10px; }
.mt-5 { margin-top: 5px; }

/*------------------------------------------*/
/*  Margin Bottom
/*------------------------------------------*/

.mb-100 { margin-bottom: 100px; }
.mb-90 { margin-bottom: 90px; }
.mb-80 { margin-bottom: 80px; }
.mb-70 { margin-bottom: 70px; }
.mb-60 { margin-bottom: 60px; }
.mb-50 { margin-bottom: 50px; }
.mb-45 { margin-bottom: 45px; }
.mb-40 { margin-bottom: 40px; }
.mb-35 { margin-bottom: 35px; }
.mb-30 { margin-bottom: 30px; }
.mb-25 { margin-bottom: 25px; }
.mb-20 { margin-bottom: 20px; }
.mb-15 { margin-bottom: 15px; }
.mb-10 { margin-bottom: 10px; }
.mb-5 { margin-bottom: 5px; }
.mb-0 { margin-bottom: 0; }

/*------------------------------------------*/
/*  Margin Left
/*------------------------------------------*/

.ml-70 { margin-left: 70px; }
.ml-60 { margin-left: 60px; }
.ml-50 { margin-left: 50px; }
.ml-45 { margin-left: 45px; }
.ml-40 { margin-left: 40px; }
.ml-35 { margin-left: 35px; }
.ml-30 { margin-left: 30px; }
.ml-25 { margin-left: 25px; }
.ml-20 { margin-left: 20px; }
.ml-15 { margin-left: 15px; }
.ml-10 { margin-left: 10px; }
.ml-5 { margin-left: 5px; }

/*------------------------------------------*/
/*  Margin Right
/*------------------------------------------*/

.mr-70 { margin-right: 70px; }
.mr-60 { margin-right: 60px; }
.mr-50 { margin-right: 50px; }
.mr-45 { margin-right: 45px; }
.mr-40 { margin-right: 40px; }
.mr-35 { margin-right: 35px; }
.mr-30 { margin-right: 30px; }
.mr-25 { margin-right: 25px; }
.mr-20 { margin-right: 20px; }
.mr-15 { margin-right: 15px; }
.mr-10 { margin-right: 10px; }
.mr-5 { margin-right: 5px; }

/*------------------------------------------*/
/*  Padding Top
/*------------------------------------------*/

.pt-100 { padding-top: 100px; }
.pt-90 { padding-top: 90px; }
.pt-80 { padding-top: 80px; }
.pt-70 { padding-top: 70px; }
.pt-60 { padding-top: 60px; }
.pt-50 { padding-top: 50px; }
.pt-45 { padding-top: 45px; }
.pt-40 { padding-top: 40px; }
.pt-35 { padding-top: 35px; }
.pt-30 { padding-top: 30px; }
.pt-25 { padding-top: 25px; }
.pt-20 { padding-top: 20px; }
.pt-15 { padding-top: 15px; }
.pt-10 { padding-top: 10px; }

/*------------------------------------------*/
/*  Padding Bottom
/*------------------------------------------*/

.pb-100 { padding-bottom: 100px; }
.pb-90 { padding-bottom: 90px; }
.pb-80 { padding-bottom: 80px; }
.pb-70 { padding-bottom: 70px; }
.pb-60 { padding-bottom: 60px; }
.pb-50 { padding-bottom: 50px; }
.pb-45 { padding-bottom: 45px; }
.pb-40 { padding-bottom: 40px; }
.pb-35 { padding-bottom: 35px; }
.pb-30 { padding-bottom: 30px; }
.pb-25 { padding-bottom: 25px; }
.pb-20 { padding-bottom: 20px; }
.pb-15 { padding-bottom: 15px; }
.pb-10 { padding-bottom: 10px; }
.pb-0 { padding-bottom: 0; }

/*------------------------------------------*/
/*  Padding Left
/*------------------------------------------*/

.pl-100 { padding-left: 100px; }
.pl-90 { padding-left: 90px; }
.pl-80 { padding-left: 80px; }
.pl-70 { padding-left: 70px; }
.pl-60 { padding-left: 60px; }
.pl-50 { padding-left: 50px; }
.pl-45 { padding-left: 45px; }
.pl-40 { padding-left: 40px; }
.pl-35 { padding-left: 35px; }
.pl-30 { padding-left: 30px; }
.pl-25 { padding-left: 25px; }
.pl-20 { padding-left: 20px; }
.pl-15 { padding-left: 15px; }
.pl-10 { padding-left: 10px; }
.pl-5 { padding-left: 5px; }
.pl-0 { padding-left: 0px; }

/*------------------------------------------*/
/*  Padding Right
/*------------------------------------------*/

.pr-100 { padding-right: 100px; }
.pr-90 { padding-right: 90px; }
.pr-80 { padding-right: 80px; }
.pr-70 { padding-right: 70px; }
.pr-60 { padding-right: 60px; }
.pr-50 { padding-right: 50px; }
.pr-45 { padding-right: 45px; }
.pr-40 { padding-right: 40px; }
.pr-35 { padding-right: 35px; }
.pr-30 { padding-right: 30px; }
.pr-25 { padding-right: 25px; }
.pr-20 { padding-right: 20px; }
.pr-15 { padding-right: 15px; }
.pr-10 { padding-right: 10px; }
.pr-5 { padding-right: 5px; }
.pr-0 { padding-right: 0px; }

/*------------------------------------------*/
/*  Padding Centered
/*------------------------------------------*/

.pc-45 { padding-right: 45px; padding-left: 45px; }
.pc-40 { padding-right: 40px; padding-left: 40px; }
.pc-35 { padding-right: 35px; padding-left: 35px; }
.pc-30 { padding-right: 30px; padding-left: 30px; }
.pc-25 { padding-right: 25px; padding-left: 25px; }
.pc-20 { padding-right: 20px; padding-left: 20px; }
.pc-15 { padding-right: 15px; padding-left: 15px; }

/*------------------------------------------*/
/*  BACKGROUND SETTINGS
/*------------------------------------------*/

.bg-fixed,
.bg-scroll {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.bg-scroll {
  background-attachment: fixed !important;
}

/*------------------------------------------*/
/*  Background Colors for Sections
/*------------------------------------------*/

.bg-white { background-color: #fff!important; }
.bg-lightdark { background-color: #35363a; }
.bg-dark { background-color: #2e2f33!important; }
.bg-lightgrey { background-color: #f8f9fb; }
.bg-blue { background-color: #00a3c8; }
.bg-deepblue { background-color: #004a91; }
.bg-steelblue { background-color: #004861; }
.bg-darkgreen { background-color: #0dc176; }
.bg-lime { background-color: #89be19; }

/*------------------------------------------*/
/*   BORDER SETTINGS FOR DEMOS
/*------------------------------------------*/

.b-top { border-top: 1px dashed #e9e9e9; }
.b-bottom { border-bottom: 1px dashed #e9e9e9; }
.b-left { border-left: 1px dashed #e9e9e9; }
.b-right { border-right: 1px dashed #e9e9e9; }

#stlChanger {
  position: fixed;
  z-index: 9999;
  font-size: 13px;
  font-family: 'Lato', sans-serif;
  overflow: hidden;
  right: -230px;
  top: 125px;
  cursor: pointer;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

#stlChanger.open {
  right: 0px;
}

#stlChanger .bgChanger {
  min-width: 280px;
  min-height: 280px;
}

#stlChanger .blockChanger {
  width: 230px;
}

#stlChanger .chBody {
  background: #f9f9f9;
  width: 230px;
  position: relative;
  overflow: hidden;
  border: 1px solid #e0e0e0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  height: 600px;
  overflow: scroll;
  overflow-x: hidden;
  direction: rtl;
  margin-left: 50px;
}

#stlChanger .chBut {
  background: #00a3c8;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 15px;
  left: 0;
  z-index: 1000000;
  text-align: center;
  border: 1px solid #00a3c8;
  border-left: none;
  border-radius: 6px 0px 0px 6px;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  box-shadow: 0 0 2px rgba(50, 50, 50, 0.4);
}

#stlChanger .chBut i {
  color: #fff;
  font-size: 33px;
  line-height: 46px;
}

#stlChanger p {
  color: #444;
  font-size: 15px;
  font-family: 'Lato', sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 20px;
}

#stlChanger .stBgs a {
  text-decoration: none;
  width: 50px;
  height: 50px;
  float: left;
  padding: 0;
  margin: 0 4px 8px;
  cursor: pointer;
  opacity: 1;
}

#stlChanger .stBgs a:hover {
  opacity: 0.7;
}

.stBlock1 {
  margin: 0 20px 25px 20px;
}

.stBlock1 .btn {
  width: 100%;
  font-size: 13px;
  line-height: 1;
  padding: 10px 22px;
}

.s_1 img {
  opacity: 1;
  margin-bottom: 12px;
  border: 1px solid #ddd;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  -ms-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}

.s_1 img:hover {
  opacity: 0.8;
}