.review-1 {
  margin: 0 5px 40px;
  padding: 0 10px;
}

.review-2 {
  position: relative;
  background-color: #fff;
  border: 1px solid #ddd;
  margin: 0 10px 40px;
  padding: 40px 30px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  border-radius: 6px;
}

.bg-lightgrey .review-2 {
  background-color: #fff;
  border: 1px solid #f5f5f5;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
}

/*------------------------------------------*/
/*    Testimonial Message Avatar
/*------------------------------------------*/

#reviews-1 .testimonial-avatar {
  display: inline-block;
  float: left;
}

/*------------------------------------------*/
/*    Testimonial Message Avatar
/*------------------------------------------*/

#reviews-1 .testimonial-avatar img,
#reviews-2 .testimonial-avatar img {
  width: 55px;
  height: 55px;
  display: inline-block;
  margin: 0 15px 0 0;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}

#reviews-2 .testimonial-avatar img {
  width: 70px;
  height: 70px;
  margin: 0 0 30px 0;
}

/*------------------------------------------*/
/*   Testimonial Message Text
/*------------------------------------------*/

.review-1 p {
  color: #757575;
  font-size: 1rem;
  line-height: 1.45;
  font-weight: 300;
  font-style: italic;
  padding: 0 8px 6px;
}

.review-2 p {
  color: #444;
  font-size: 1.35rem;
  font-family: 'Lato', sans-serif;
  line-height: 1.35;
  font-weight: 400;
}

.review-author h5 {
  font-size: 1.05rem;
  line-height: 1;
  padding-top: 10px;
  margin-bottom: 0px;
}

#reviews-2 .review-author h5 {
  font-size: 0.775rem;
  line-height: 1;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 5px;
  padding-top: 10px;
  margin-bottom: 0px;
}

.review-author span {
  color: #777;
  font-size: 0.915rem;
  line-height: 1;
  font-weight: 400;
}

#reviews-2 .review-author span {
  color: #959595;
  font-size: 0.7rem;
  line-height: 1;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 4px;
}

/*------------------------------------------*/
/*   Testimonial Quote
/*------------------------------------------*/

.quote {
  width: 60px;
  height: 62px;
  margin-left: 5px;
  margin-bottom: -30px;
  position: relative;
  z-index: 3;
  opacity: .15;
}

.review-2 .quote {
  position: absolute;
  top: 54%;
  left: 50%;
  width: 100px;
  height: 103px;
  margin-left: -62px;
  margin-top: -60px;
  opacity: .14;
}

/*------------------------------------------*/
/*   Services Owl Carousel Dots
/*------------------------------------------*/

.reviews-section .owl-theme .owl-dots .owl-dot span {
  background: transparent;
  border: 2px solid #004861;
}

.reviews-section .owl-theme .owl-dots .owl-dot.active span,
.reviews-section .owl-theme .owl-dots .owl-dot:hover span {
  background: #004861;
}