.questions-holder h3 {
  margin-bottom: 30px;
}

#faqs-page h5.h5-xl {
  margin-top: 40px;
  margin-bottom: 35px;
}

/*------------------------------------------*/
/*  ACCORDION
/*------------------------------------------*/

#faqs-page .card-header {
  padding: 22px 0;
  background-color: transparent;
  border: none;
  position: relative;
}

#faqs-page #accordion [data-toggle="collapse"].collapsed:after,
#faqs-page #accordion [data-toggle="collapse"]:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f068";
  position: absolute;
  font-size: 13px;
  top: 25px;
  right: 5px;
}

#faqs-page #accordion [data-toggle="collapse"].collapsed:after,
#faqs-page #accordion [data-toggle="collapse"]:after {
  color: #00a3c8;
  font-size: 13px;
  top: 25px;
  right: 5px;
}

#faqs-page #accordion [data-toggle="collapse"].collapsed:after {
  color: #00a3c8;
  content: "\f067";
}

/*------------------------------------------*/
/*  Accordion Panel Title
/*------------------------------------------*/

#accordion .card {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #ccc;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

#accordion .card.no-border {
  border-bottom: none;
}

#accordion .card-header h5 {
  font-weight: 600;
  line-height: 1;
  padding-right: 5%;
  margin-bottom: 0;
}

#faqs-page #accordion .card-header a {
  color: #555;
}

/*------------------------------------------*/
/*  Accordion Panel Content
/*------------------------------------------*/

#accordion .card-body {
  padding: 5px 0 15px 0;
}

#accordion .card-body p {
  color: #666;
}

#accordion .card-body .content-list {
  margin-bottom: 1rem;
}