#tabs-1 .section-title {
  margin-bottom: 40px;
}

#pills-tab {
  margin: 0 auto 60px;
}

#tabs-2  #pills-tab {
  width: 100%;
  margin: 10px 0 0;
  padding-right: 30px;
}

#tabs-2 .nav-item {
  width: 100%;
  margin: 0;
  text-align: left;
}

.nav-pills .nav-link {
  background-color: transparent;
  border: 1px solid transparent;
  color: #ccc;
  font-size: 1rem;
  font-weight: 400;
  padding: 18px 35px;
  margin-right: 15px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

#tabs-2 .nav-pills .nav-link {
  background-color: transparent;
  border: none;
  border-right: 2px solid transparent;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  padding: 17px 20px;
  margin-right: 0;
  margin-bottom: 4px;
}

.nav-pills .nav-link span {
  float: left;
  margin-top: -8px;
  margin-right: 10px;
}

.nav-pills .icon-xs [class*="flaticon-"]:before,
.nav-pills .icon-xs [class*="flaticon-"]:after {
  font-size: 2.5rem;
  line-height: 1!important;
}

.nav-pills .nav-link a {
  line-height: 2.5rem!important
}

.nav-pills .nav-link.active,
.nav-pills .nav-link.active:hover {
  background-color: #00a3c8;
  border-color: #00a3c8;
  color: #fff;
}

#tabs-2 .nav-pills .nav-link.active,
#tabs-2 .nav-pills .nav-link.active:hover {
  background-color: #f8f8f8;
  color: #333;
  border: none;
  border-right: 2px solid #00a3c8;
}

.nav-pills .nav-link:hover {
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  color: #999;
}

#tabs-2 .tab-content h3 {
  margin-bottom: 20px;
}

#tabs-2 .tab-img {
  margin-top: 30px;
  margin-bottom: 30px;
}