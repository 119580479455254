#services-6 {
  background-image: url(../../../../../frontend/src/assets/images/banner-2.jpg);
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed !important;
}

#services-6 .container {
  overflow: hidden;
}

#services-8 {
  position: relative;
}

.services-8-bg {
  background-color: #f8f9fb;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  left: 50%;
  z-index: 3;
}

.s8-holder {
  z-index: 4;
}

.s8-boxes {
  z-index: 4;
  padding-left: 60px;
}

/*------------------------------------------*/
/*  SERVICE BOX
/*------------------------------------------*/

.sbox-1,
.sbox-4,
.sbox-6 {
  margin-bottom: 50px;
}

.sbox-2 {
  text-align: center;
  margin-bottom: 30px;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 60px 10px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.sbox-2:hover,
.sbox-7:hover,
.bg-lightgrey .sbox-7:hover {
  background-color: #fafafa;
  border: 1px solid #f5f5f5;
  -webkit-box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
}

.sbox-2:hover .sbox-2-icon {
  -webkit-transform: translate3d(0, 40px, 0);
  transform: translate3d(0, 40px, 0);
}

.sbox-2:hover h5.sbox-2-title {
  -webkit-transform: translate3d(0, -110px, 0);
  transform: translate3d(0, -110px, 0);
}

.bg-lightgrey .sbox-2:hover {
  background-color: #00a3c8;
  border: 1px solid #00a3c8;
}

.bg-lightgrey .sbox-2:hover .sbox-2-icon,
.bg-lightgrey .sbox-2:hover h5.sbox-2-title {
  color: #fff;
}

.sbox-3 {
  text-align: center;
  background-color: #fff;
  border: 1px solid #ddd;
  margin: 0 8px 40px;
  padding: 40px 20px 40px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

.sbox-5 {
  text-align: center;
  background-color: #fff;
  border: 1px solid #ddd;
  margin: 0 8px 40px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

.sbox-5 img {
  -webkit-border-radius: 6px 6px 0 0;
  -moz-border-radius: 6px 6px 0 0;
  border-radius: 6px 6px 0 0;
}

.sbox-7 {
  background-color: #fff;
  border: 1px solid #ddd;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  padding: 30px;
  margin-bottom: 30px;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  transition: all 450ms ease-in-out;
}

.bg-lightgrey .sbox-2,
.bg-lightgrey .sbox-3,
.bg-lightgrey .sbox-5,
.bg-lightgrey .sbox-7 {
  background-color: #fff;
  border: 1px solid #f5f5f5;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/*------------------------------------------*/
/*   SERVICE BOX TEXT
/*------------------------------------------*/

.sbox-4-txt {
  margin-top: 15px;
}

.sbox-5-txt {
  padding: 5px 20px 40px;
}

.sbox-7-txt {
  overflow: hidden;
  padding-left: 20px;
}

/*------------------------------------------*/
/*  SERVICE BOX TYPOGRAPHY
/*------------------------------------------*/

.sbox-1 h5 {
  margin-top: 22px;
  margin-bottom: 13px;
}

.sbox-2 h5 {
  letter-spacing: -0.5px;
  margin-top: 25px;
  margin-bottom: 0;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.sbox-3 h5,
.sbox-5 h5 {
  font-size: 1.05rem;
  line-height: 1;
  font-weight: 900;
  text-transform: uppercase;
  margin-top: 25px;
  margin-bottom: 15px;
}

.sbox-4 h5 {
  line-height: 1;
  margin-top: 25px;
  margin-bottom: 15px;
}

.sbox-6 h5 {
  margin-bottom: 10px;
}

.sbox-7 h5 {
  line-height: 1;
  margin-bottom: 10px;
}

/*------------------------------------------*/
/*  Service Box Paragraph
/*------------------------------------------*/

.sbox-1 p,
.sbox-2 p,
.sbox-4 p,
.sbox-7 p {
  line-height: 1.5;
  margin-bottom: 0;
}

.sbox-3 p,
.sbox-5 p {
  color: #666;
  font-size: 0.925rem;
  line-height: 1.5;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  Service Box Icon
/*------------------------------------------*/

.sbox-2-icon {
  color: #00a3c8;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.sbox-3-icon {
  background-color: #00a3c8;
  display: inline-block;
  width: 110px;
  height: 110px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}

.sbox-3-icon.st-blue-icon {
  background-color: #004861;
}

.icon-sm .sbox-3-icon [class*="flaticon-"]:before,
.icon-sm .sbox-3-icon [class*="flaticon-"]:after {
  color: #fff;
  font-size: 3.45rem;
  line-height: 110px!important;
}

.sbox-6-img {
  position: relative;
  text-align: center;
  margin-bottom: 40px;
}

.sbox-7 span {
  float: left;
  text-align: center;
  margin-top: 4px;
}

/*------------------------------------------*/
/*   Services 7 Table
/*------------------------------------------*/

.services-7-table {
  background-color: #f9f9f9;
  border: 1px solid #f5f5f5;
  padding: 35px 40px 30px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  margin-bottom: 30px;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.services-8-table {
  padding: 0 80px 0 40px;
  margin-bottom: 40px;
}

.services-7-table.blue-table {
  background-color: #00a3c8;
  border: 1px solid #00a3c8;
  color: #fff;
  box-shadow: none;
}

.services-7-table h5,
.services-7-table h4 {
  margin-bottom: 15px;
}

.services-8-table h4 {
  margin-bottom: 20px;
}

.services-8-table h5 {
  margin-bottom: 15px;
}

.services-8-table p {
  margin-bottom: 0;
}

.services-7-table table {
  margin-top: 15px;
  margin-bottom: 20px;
}

.services-8-table table {
  margin-top: 25px;
  margin-bottom: 25px;
}

.services-7-table.blue-table h5,
.services-7-table.blue-table h4,
.services-7-table.blue-table table {
  color: #fff;
}

.services-7-table .table td, .services-7-table .table th {
  font-size: 16px;
  padding: 16px 0;
  border-top: none;
  border-bottom: 1px dashed #aaa;
}

.services-7-table.blue-table .table td,
.services-7-table.blue-table .table th {
  border-bottom: 1px dashed rgba(255, 255, 255, .6);
}

.services-8-table .table td, .services-8-table .table th {
  color: #fff;
  font-size: 16px;
  padding: 18px 0;
  font-family: 'Lato', sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  border-top: none;
  border-bottom: 1px dashed rgba(255, 255, 255, .6);
}

.services-7-table .table .last-tr td,
.services-7-table .table .last-tr th,
.services-8-table .table .last-tr td,
.services-8-table .table .last-tr th {
  border-bottom: none;
}

/*------------------------------------------*/
/*   Services Owl Carousel Dots
/*------------------------------------------*/

.services-section .owl-theme .owl-dots .owl-dot span {
  background: transparent;
  border: 2px solid #004861;
}

.services-section .owl-theme .owl-dots .owl-dot.active span,
.services-section .owl-theme .owl-dots .owl-dot:hover span {
  background: #004861;
}

/*------------------------------------------*/
/*  SERVICE DETAILS
/*------------------------------------------*/

.s1-page h3.h3-xl {
  letter-spacing: -0.5px;
  margin-bottom: 8px;
}

.s2-page h3.h3-md {
  margin-bottom: 10px;
}

.s1-page h4.h4-md,
.s2-page h4.h4-sm {
  font-weight: 400;
  margin-bottom: 25px;
}

.s1-page h4.h4-lg {
  font-size: 1.5rem;
  line-height: 2.25rem;
  font-weight: 400;
  margin-bottom: 8px;
}

.s1-page h4.h4-lg span {
  font-size: 2.25rem;
  line-height: 1;
  font-weight: 900;
  position: relative;
  top: 4px;
  left: 5px;
}

.s1-page h5 {
  color: #757575;
  font-weight: 400;
  margin-bottom: 0;
}

.s2-page h5.h5-md {
  margin-top: 15px;
  margin-bottom: 25px;
}

.s1-page .btn {
  margin-top: 35px;
  margin-bottom: 35px;
}

.s2-page .btn {
  margin-top: 20px;
  margin-bottom: 0;
}

.s2-page .pricing-table {
  margin-top: 40px;
  margin-bottom: 40px;
}