#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: #fff;
}

#loader {
  width: 256px;
  height: 38px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -128px;
  margin-top: -19px;
}

.loader-inner {
  width: 256px;
  height: 38px;
  background: url('../../../../../frontend/src/assets/images/loader.gif') center center no-repeat;
}