
//#contacts-1.bg-image {
//  background-image: url(../../../../../frontend/src/assets/images/contacts-1.jpg);
//}

/*------------------------------------------*/
/*   CONTACT BOX
/*------------------------------------------*/

.contact-box h5 {
  line-height: 1;
  font-weight: 700;
  margin-bottom: 15px;
}

.contact-box p {
  font-size: 0.975rem;
  margin-bottom: 2px;
}

.contact-box p a {
  font-weight: 500;
}

#contacts-2 h4.h4-md {
  line-height: 1.25;
  padding-right: 30px;
  margin-top: 20px;
  margin-bottom: 25px;
}

p.contact-notice {
  padding-right: 30px;
  margin-top: 25px;
  margin-bottom: 40px;
}

/*------------------------------------------*/
/*  CONTACT FORM
/*------------------------------------------*/

#contacts-1 .contact-form {
  margin-left: 20px;
}

#contacts-2 .contact-form {
  padding: 40px 20px 35px;
  margin: 0;
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

#contacts-2 .contact-form .col-md-6,
#contacts-2 .contact-form .col-md-12 {
  padding: 0;
}

.form-group {
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  Contact Form Input
/*------------------------------------------*/

.contact-form .form-control,
.contact-form .custom-select {
  background-color: #fff;
  border: 1px solid #bbb;
  color: #333;
  height: 52px;
  font-size: 15px;
  line-height: 1;
  font-weight: 300;
  padding: 4px 15px;
  margin-bottom: 15px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  box-shadow: none;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.contact-form .custom-select {
  display: inline-block;
  width: 100%;
  color: #777;
}

/*------------------------------------------*/
/*  Contact Form Textarea
/*------------------------------------------*/

.contact-form textarea { min-height: 200px; }
#contacts-2 .contact-form textarea { min-height: 140px; }
.contact-form textarea.form-control { padding: 20px 15px; }

/*------------------------------------------*/
/*  Contact Form Placeholder
/*------------------------------------------*/

.contact-form .form-control::-moz-placeholder { color: #777; }
.contact-form .custom-select::-moz-placeholder { color: #777; }
.contact-form .form-control:-ms-input-placeholder { color: #777; }
.contact-form .custom-select:-ms-input-placeholder { color: #777; }
.contact-form .form-control::-webkit-input-placeholder { color: #777; }
.contact-form .custom-select::-webkit-input-placeholder { color: #777; }

/*------------------------------------------*/
/*  Contact Form Input Focus
/*------------------------------------------*/

.contact-form .form-control:focus {
  background-color: #fff;
  border-color: #00a3c8;
  outline: 0px none;
  box-shadow: none;
}

/*------------------------------------------*/
/*  Contact Form Button
/*------------------------------------------*/

.contact-form .btn:focus {
  outline: 0px none;
  box-shadow: none;
}

/*------------------------------------------*/
/*  Contact Form Message
/*------------------------------------------*/

.contact-form label.error {
  color: #e74c3c;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 20px;
}

.contact-form-msg {
  width: 100%!important;
  display: block;
  text-align: center;
}

.contact-form .sending-msg {
  margin-top: 20px;
}

.contact-form .loading {
  color: #666;
  font-size: 17px;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
}